import { useEffect, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import _ from 'lodash';
import { isNil } from 'lodash';
import { useIndexedDB } from 'react-indexed-db';
import { useIntl } from 'react-intl';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { ColorBox } from '../../../../components/ColorBox/ColorBox';
import { LegendWrapper } from '../../../../components/LegendWrapper/LegendWrapper';
import { I18nKey } from '../../../../translations/I18nKey';
import { ProductUnitsContext } from '../../../Contexts/UnitsContext';
import { UserSettingsContext } from '../../../Contexts/UserSettingsContext';
import { FeaturesContext } from '../../FeaturesContext';
import { MapContext } from '../../MapContext';
import { findCursorData } from '../MapLayers/CursordataService';
import {
  getSelectedProductsFromStorage,
  getUniqueProducts,
  isAnyProductSelected,
} from '../../HelperService';
import { RasterService } from '../MapLayers/RasterService';
import { ProductLegendStyles } from './ProductLegendStyles';
import {
  LegendRasterProps,
  LegendVectorContourProps,
} from './ProductLegendTypes';
import { CursorData } from '../../../types';
import moment from 'moment';
import './legend.scss';
import ErrorIcon from '@mui/icons-material/Error';

export const ProductLegend: React.FC<any> = ({
  selectedProducts,
  hideLegend,
  onOpenProductSettings,
}) => {
  const intl = useIntl();
  const date = useContextSelector(MapContext, (state) => state.date);
  const displayConfig = useContextSelector(
    FeaturesContext,
    (state) => state.displayConfig
  );
  const tileData = useContextSelector(
    FeaturesContext,
    (state) => state.tileData
  );
  const setDisplayConfig = useContextSelector(
    FeaturesContext,
    (state) => state.setDisplayConfig
  );
  const userData = useContextSelector(
    FeaturesContext,
    (state) => state.userData
  );
  const removeMultipleMapTiles = useContextSelector(
    FeaturesContext,
    (state) => state.removeMultipleMapTiles
  );
  const usePreviousDate = useContextSelector(
    FeaturesContext,
    (state) => state.usePreviousDate
  );
  const setUsePreviousDate = useContextSelector(
    FeaturesContext,
    (state) => state.setUsePreviousDate
  );

  const productAvailabilityDate = useContextSelector(
    FeaturesContext,
    (state) => state.productAvailabilityDate
  );
  const units = useContextSelector(ProductUnitsContext, (state) => state);
  const map = useContextSelector(MapContext, (state) => state.map);
  const userSettings = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings
  );
  const { getByIndex } = useIndexedDB('cursorData');
  const [rasterProduct, setRasterProduct] = useState({
    productCode: '',
    unit: '',
  });
  const [rasterColorScale, setRasterColorStyle] = useState('');
  const [scale, setScale] = useState<any>([]);
  const [scalePointerPosition, setScalePointerPosition] = useState(0);
  const [selectedContourVectors, setSelectedContourVectors] = useState<any>({});
  const [selectedContourVectorsList, setSelectedContourVectorsList] = useState<
    string[][]
  >([]);
  const [cursorDataValues, setCursorDataValues] = useState<any>({});

  const closeProductsOnUnitChange = useContextSelector(
    UserSettingsContext,
    (state) => state.closeProductsOnUnitChange
  );

  let timeout = 0;
  let showCursorDataFromLayer = true;

  useEffect(() => {
    if (!isNil(selectedProducts)) {
      setCursorDataValues({});
      setRasterData();
      setContourVectorData();
    }
  }, [selectedProducts]);

  useEffect(() => {
    let key = isAnyProductSelected(selectedProducts);
    if (key && map) {
      map.on('mousemove', fetchCursordataValue);
    }
    return () => {
      map?.off('mousemove', fetchCursordataValue);
    };
  }, [map, selectedProducts]);

  useEffect(() => {
    if (isAnyProductSelected(selectedProducts) && closeProductsOnUnitChange) {
      onCloseProducts();
    }
  }, [closeProductsOnUnitChange]);

  const fetchCursordataValue = (e: any) => {
    if (isAnyProductSelected(getSelectedProductsFromStorage())) {
      let selectedProductList: any = showCursorDataFromLayer
        ? _.pick(getSelectedProductsFromStorage(), ['raster']).raster
        : getUniqueProducts(selectedProducts);
      if (selectedProductList.length) {
        if (timeout !== undefined) {
          window.clearTimeout(timeout);
        }
        timeout = window.setTimeout(() => {
          selectedProductList.forEach((pCode: string) => {
            if (
              userSettings.map?.regionsOfInterest &&
              displayConfig.data[userData[pCode]?.category]?.items[pCode]
                .cursorData
            ) {
              let cursorValue = findCursorData(
                e.lngLat,
                pCode,
                userSettings.map?.regionsOfInterest,
                userData,
                units,
                getByIndex,
                usePreviousDate
              );
              cursorValue.then((value) => {
                setCursorDataValues({ ..._.merge(cursorDataValues, value) });
              });
            }
          });
        }, 100);
      }
      if (showCursorDataFromLayer) {
        //getting cursor data from features
        let contourAndVectorProducts: any = _.pick(
          getSelectedProductsFromStorage(),
          ['contour', 'vector']
        );
        let layers = _.flatten(
          Object.keys(contourAndVectorProducts).map((t) => {
            return contourAndVectorProducts[t].map((p: any) => {
              return p + t;
            });
          })
        );
        const features = map?.queryRenderedFeatures(e.point, {
          layers: layers,
        });
        let codes = getUniqueProducts(contourAndVectorProducts);
        features?.forEach((f) => {
          let code: any = codes.find((c: any) => f.source.includes(c));
          let type = f.source.split(code)[1];
          if (code && f.properties) {
            let data: any = {};
            data[code] =
              type === 'contour'
                ? f.properties['level-value'] //TODO: take label value from config
                : f.properties['velocity'];
            setCursorDataValues({
              ..._.merge(cursorDataValues, data),
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    let u =
      units[rasterProduct.productCode]?.unitSection !== ' '
        ? units[rasterProduct.productCode]?.unit
        : '';
    setScalePointerPosition(
      RasterService.updatePointerPosition(
        userData[rasterProduct.productCode]?.scaleType,
        Number(cursorDataValues[rasterProduct.productCode.toLowerCase() + u])
      )
    );
  }, [cursorDataValues]);

  const setRasterData = () => {
    let rasterCode = selectedProducts.raster[0];
    if (rasterCode && displayConfig.data) {
      Object.values(displayConfig?.data).forEach((data: any) => {
        if (rasterCode) {
          if (data.items[rasterCode] && tileData[rasterCode].raster.tile) {
            let product = {
              productCode: rasterCode,
              unit: units[rasterCode]['unit'] || '',
            };
            setRasterProduct(product);
            let colorScale = RasterService.getSliderBackground(
              data.items[rasterCode].raster.colorScale,
              tileData[rasterCode].raster.tile.colorScaleType,
              'right'
            );
            setRasterColorStyle(colorScale);
            let scaleData = RasterService.setRasterColorLegend(
              data.items[rasterCode].scaleType,
              tileData[rasterCode].raster.tile.min,
              tileData[rasterCode].raster.tile.max,
              data.items[rasterCode].precision
            );
            setScale([...scaleData]);
          }
        }
      });
    }
    if (!rasterCode) {
      setRasterProduct({
        productCode: '',
        unit: '',
      });
      setRasterColorStyle('');
      setScale([]);
    }
  };

  const setContourVectorData = () => {
    let prods: any = {};
    selectedProducts?.contour?.length > 0 &&
      selectedProducts?.contour.forEach((code: string) => {
        if (tileData[code]) {
          prods[code] = getDisplayData(code, 'contour');
        }
      });
    selectedProducts?.vector?.length > 0 &&
      selectedProducts?.vector.forEach((code: string) => {
        if (tileData[code]) {
          prods[code] = getDisplayData(code, 'vector');
        }
      });
    selectedProducts?.weather?.length > 0 &&
      selectedProducts?.weather.forEach((code: string) => {
        prods[code] = {};
      });
    setSelectedContourVectors(prods);
    createLegendList(prods);
  };

  const getDisplayData = (code: string, type: string) => {
    return {
      color: tileData[code][type]?.color,
      unit: units[code]
        ? intl.formatMessage({
            id: I18nKey[`UNITS_${units[code]['unit'].toUpperCase()}`],
          })
        : '',
    };
  };

  const createLegendList = (prods: any) => {
    let products: string[][] = [];
    let p: string[] = [];
    let c = 0;
    let filteredProds = Object.keys(prods).filter((k) => _.isEmpty(prods[k]));
    let availableProds = _.omit(prods, filteredProds);
    _.sortBy(Object.keys(availableProds), (p) => {
      return displayConfig.data[userData[p]?.category]?.items[p]
        .productDialogOrder;
    }).forEach((x) => {
      p.push(x);
      c++;
      if (c % 2 === 0) {
        products.push(p);
        c = 0;
        p = [];
      }
    });
    if (c !== 0) {
      products.push(p);
    }
    if (filteredProds.length) {
      let list: any = [];
      filteredProds.forEach((p) => {
        list.push(p);
        if (list.length === 2) {
          products.push(list);
          list = [];
        }
      });
      if (list.length !== 0) {
        products.push(list);
      }
    }
    setSelectedContourVectorsList(products);
  };

  const onCloseProducts = () => {
    const displayDefaults = Object.assign({}, displayConfig);
    let dataList: { code: string; type: string }[] = [];
    Object.keys(selectedProducts).forEach((type) => {
      selectedProducts[type].forEach((code: string) => {
        dataList.push({ code: code, type: type });
        displayDefaults.data[userData[code].category].items[code][
          type
        ].selected = false;
      });
    });
    removeMultipleMapTiles(dataList);
    setDisplayConfig(displayDefaults);
    hideLegend();
    setUsePreviousDate(false);
  };

  const getProductEffectiveDate = (p: string) => {
    let inputDate = moment(date, 'YYYYMMDD').toDate();
    let productDate = '';
    if (productAvailabilityDate[p]?.length) {
      productDate =
        inputDate.setHours(0, 0, 0, 0) !==
        moment(productAvailabilityDate[p].substring(0, 8))
          .toDate()
          .setHours(0, 0, 0, 0)
          ? moment(productAvailabilityDate[p].substring(0, 8)).format(
              'MM-DD-yyyy'
            )
          : '';
    }

    return productDate;
  };

  return (
    <LegendWrapper
      title={
        rasterProduct.productCode &&
        intl.formatMessage({
          id: I18nKey[`PRODUCTS_${rasterProduct.productCode.toUpperCase()}`],
        })
      }
      subTitle={
        rasterProduct.productCode &&
        rasterProduct.unit &&
        `(${intl.formatMessage({
          id: I18nKey[`UNITS_${rasterProduct.unit.toUpperCase()}`],
        })})`
      }
      titleElement={
        getProductEffectiveDate(rasterProduct.productCode).length ? (
          <Tooltip
            title={`${intl.formatMessage({
              id: I18nKey.LEGEND_PRODUCT_AVAILABILITY_TOOLTIP,
            })} ${getProductEffectiveDate(rasterProduct.productCode)}`}
            placement="top"
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              {getProductEffectiveDate(rasterProduct.productCode).length ? (
                <>
                  <ErrorIcon
                    className="blink"
                    sx={ProductLegendStyles.dateIcon}
                  ></ErrorIcon>
                  <Typography sx={ProductLegendStyles.date}>
                    {getProductEffectiveDate(rasterProduct.productCode)}
                  </Typography>
                </>
              ) : null}
            </Box>
          </Tooltip>
        ) : undefined
      }
      onSettingsClick={onOpenProductSettings}
      wrapperStyle={ProductLegendStyles.wrapper}
      showSeparator={
        rasterProduct.productCode && selectedContourVectorsList.length > 0
          ? true
          : false
      }
      leftElement={
        rasterProduct?.productCode ? (
          <RasterElement
            rasterProduct={rasterProduct}
            rasterColorScale={rasterColorScale}
            scale={scale}
            cursorDataValues={cursorDataValues}
            scalePointerPosition={scalePointerPosition}
          ></RasterElement>
        ) : undefined
      }
      rightElement={
        selectedContourVectorsList?.length > 0 ? (
          <ContourVectorElement
            selectedContourVectorsList={selectedContourVectorsList}
            selectedContourVectors={selectedContourVectors}
            cursorDataValues={cursorDataValues}
            getProductEffectiveDate={getProductEffectiveDate}
          ></ContourVectorElement>
        ) : undefined
      }
      rightElementStyle={ProductLegendStyles}
      leftElementStyle={ProductLegendStyles}
      onCloseClick={onCloseProducts}
    ></LegendWrapper>
  );
};

const RasterElement: React.FC<LegendRasterProps> = ({
  rasterProduct,
  rasterColorScale,
  scale,
  cursorDataValues,
  scalePointerPosition,
}) => {
  const units = useContextSelector(ProductUnitsContext, (state) => state);
  let u =
    units[rasterProduct.productCode]?.unitSection !== ' '
      ? units[rasterProduct.productCode]?.unit
      : '';
  return (
    <>
      {rasterProduct.productCode && (
        <>
          <Box width={'100%'}>
            <Box
              sx={{
                ...ProductLegendStyles.scalePointer,
                ...{
                  left: `${Number(scalePointerPosition)}px`,
                },
              }}
            >
              <Box component="span" sx={ProductLegendStyles.scalePointerValue}>
                {cursorDataValues[
                  rasterProduct.productCode.toLowerCase() + u
                ] || 0}
              </Box>
            </Box>
            <Box
              sx={{
                ...ProductLegendStyles.scaleDisplay,
                ...{ backgroundImage: rasterColorScale },
              }}
            ></Box>
            <Box sx={ProductLegendStyles.scale}>
              <Stack direction="row" justifyContent="space-evenly">
                {scale.map((v: number, index: number) => {
                  return (
                    <Box key={index} sx={ProductLegendStyles.scaleValue}>
                      {v}
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

const ContourVectorElement: React.FC<LegendVectorContourProps> = ({
  selectedContourVectorsList,
  selectedContourVectors,
  cursorDataValues,
  getProductEffectiveDate,
}) => {
  const intl = useIntl();
  const units = useContextSelector(ProductUnitsContext, (state) => state);
  let cols =
    Object.keys(selectedContourVectors).filter(
      (k) => !_.isEmpty(selectedContourVectors[k])
    ).length * 3;
  const weatherProducts = Object.keys(selectedContourVectors).filter((k) =>
    _.isEmpty(selectedContourVectors[k])
  );

  let weatherNotificationProducts: any[][] = [];
  let normalProducts: any[][] = [];

  selectedContourVectorsList.forEach((pr: any) => {
    if (weatherProducts.length && weatherProducts.some((r) => pr.includes(r))) {
      let list: any = [];
      pr.forEach((p: any) => {
        if (getProductEffectiveDate(p) !== '') {
          list.push(p);
          if (list.length === 2) {
            weatherNotificationProducts.push(list);
            list = [];
          }
        }
      });
      if (list.length !== 0) {
        weatherNotificationProducts.push(list);
      }
    } else normalProducts.push(pr);
  });

  return (
    <>
      {normalProducts.length > 0 && (
        <Grid
          container
          direction="row"
          columns={
            weatherNotificationProducts.length
              ? cols + weatherNotificationProducts.length * 3
              : cols
          }
        >
          {normalProducts.map((pr: any) => {
            return (
              <Grid
                item
                container
                key={`${pr}col`}
                alignContent="baseline"
                sx={{
                  marginTop: '3px',
                  marginRight: '13px',
                  width: '100px',
                }}
              >
                <Grid item key={`${pr}row`}>
                  {pr.map((p: string) => {
                    let u = units[p]?.unitSection !== ' ' ? units[p]?.unit : '';
                    let productDate = getProductEffectiveDate(p);

                    return (
                      <Stack
                        key={`${p}section`}
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{ marginTop: '10px' }}
                      >
                        <>
                          <ColorBox
                            key={p}
                            boxStyle={ProductLegendStyles.boxStyle}
                            boxText={`${intl.formatMessage({
                              id: I18nKey[`PRODUCT_CODE_${p}`],
                            })}`}
                            boxTextStyle={ProductLegendStyles.boxTextStyle}
                            color={selectedContourVectors[p].color}
                          ></ColorBox>
                          <Box
                            sx={{
                              display: 'flex',
                              color: '#FFFFFF',
                            }}
                          >
                            <Typography
                              sx={{ minWidth: '30px', fontSize: '14px' }}
                            >
                              {_.has(
                                cursorDataValues,
                                (p.toLowerCase() as keyof CursorData) + u
                              )
                                ? cursorDataValues[
                                    (p.toLowerCase() as keyof CursorData) + u
                                  ]
                                : cursorDataValues[p] || 0}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                              }}
                            >
                              ({selectedContourVectors[p].unit})
                            </Typography>
                          </Box>
                          <Tooltip
                            title={`${intl.formatMessage({
                              id: I18nKey.LEGEND_PRODUCT_AVAILABILITY_TOOLTIP,
                            })} (${productDate})`}
                          >
                            <Box
                              height={15}
                              sx={ProductLegendStyles.dateSection}
                            >
                              {productDate.length ? (
                                <>
                                  <ErrorIcon
                                    className="blink"
                                    sx={
                                      ProductLegendStyles.rightElementDateIcon
                                    }
                                  ></ErrorIcon>
                                  <Typography
                                    sx={ProductLegendStyles.rightElementDate}
                                  >
                                    {productDate}
                                  </Typography>
                                </>
                              ) : null}
                            </Box>
                          </Tooltip>
                        </>
                      </Stack>
                    );
                  })}
                </Grid>
              </Grid>
            );
          })}

          {weatherNotificationProducts.map((pr: any) => {
            return (
              <Grid
                item
                container
                key={`${pr}col`}
                alignContent="baseline"
                sx={{
                  marginTop: '3px',
                  marginRight: '13px',
                  width: '100px',
                }}
              >
                <Grid item key={`${pr}row`}>
                  {pr.map((p: string) => {
                    let productDate: any = getProductEffectiveDate(p);
                    return (
                      <>
                        <Stack
                          key={`${p}section`}
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          sx={{ marginTop: '10px' }}
                        >
                          <ColorBox
                            key={p}
                            boxText={`${intl.formatMessage({
                              id: I18nKey[`PRODUCT_CODE_${p}`],
                            })}`}
                            boxTextStyle={ProductLegendStyles.boxTextStyle}
                          ></ColorBox>
                          <Tooltip
                            title={`${intl.formatMessage({
                              id: I18nKey.LEGEND_PRODUCT_AVAILABILITY_TOOLTIP,
                            })} (${productDate})`}
                          >
                            <Box
                              height={15}
                              sx={{
                                ...ProductLegendStyles.dateSection,
                                marginBottom: '28px',
                              }}
                            >
                              {productDate.length ? (
                                <>
                                  <ErrorIcon
                                    className="blink"
                                    sx={
                                      ProductLegendStyles.rightElementDateIcon
                                    }
                                  ></ErrorIcon>
                                  <Typography
                                    sx={ProductLegendStyles.rightElementDate}
                                  >
                                    {productDate}
                                  </Typography>
                                </>
                              ) : null}
                            </Box>
                          </Tooltip>
                        </Stack>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};
