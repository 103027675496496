import { Auth } from 'aws-amplify';

export const getAmplifyConfig = () => {
  const aws_config = {
    aws_project_region: process.env.REACT_APP_PROJECT_REGION,
    aws_cognito_identity_pool_id:
      process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    oauth: process.env.REACT_APP_COGNITO_OAUTH,
  };
  return aws_config;
};

export const checkUserLogin = () => {
  return Auth.currentAuthenticatedUser();
};

export const setUserAttributes = (
  userName: string,
  attr: Record<string, any>
) => {
  const userInfo = {
    userName: userName,
    seastarId: attr['custom:orbnet_id'],
    email: attr['email'],
  };
  localStorage.setItem('UserInfo', JSON.stringify(userInfo));
};

export const getUserAttributes = () => {
  return JSON.parse(localStorage.getItem('UserInfo') || '{}');
};
