import smooth from 'to-smooth';
import { UserSettings } from '../../../types';
import { convertToUnit } from '../../HelperService';

export class ContourService {
  public static addContourLayer = (
    map: mapboxgl.Map,
    code: string,
    tileData: any,
    settings: UserSettings,
    units: any,
    displayConfig: any,
    addMapLayerId: any,
    layerOrder: any,
    setLoading: any
  ) => {
    const layerKey = code + 'contour';
    let product: any = {};
    Object.values(displayConfig).forEach((data: any) => {
      if (data.items[code]) product = data.items[code];
    });
    const layerData = ContourService.convertToCurrentUnit(
      code,
      tileData.data,
      product,
      units
    );

    layerData.features.forEach((feature: any) => {
      feature.geometry.coordinates = smooth(feature.geometry.coordinates, {
        iteration: 5,
        factor: 0.75,
      });
    });
    addMapLayerId('contour', layerKey);
    if (!map?.getSource(layerKey)) {
      map?.addSource(layerKey, {
        type: 'geojson',
        data: layerData,
      });
      map?.addLayer(
        {
          id: layerKey,
          type: 'line',
          source: layerKey,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': tileData?.color,
          },
        },
        layerOrder('contour')
      );
      this.addContourLabels(
        map,
        code,
        tileData,
        settings,
        product['extraConfigs']['contourDataLabel'],
        layerOrder
      );
    }
    setLoading(false);
  };

  public static addContourLabels(
    map: mapboxgl.Map,
    code: string,
    tileData: any,
    settings: UserSettings,
    labelName: string,
    layerOrder: any
  ) {
    const layerKey = code + 'contour';
    map?.addLayer(
      {
        id: code + 'contourLabels',
        type: 'symbol',
        source: layerKey,
        layout: {
          visibility: settings.map?.showProductContourLabel
            ? 'visible'
            : 'none',
          'symbol-placement': 'line',
          'text-font': ['Arial Unicode MS Regular'],
          'text-field': `{${labelName}}`,
          'text-size': 14,
          'text-anchor': 'bottom',
        },
        paint: {
          'text-color': tileData?.color,
        },
      },
      layerOrder('contour')
    );
  }

  public static convertToCurrentUnit(
    code: any,
    tileData: any,
    product: any,
    units: any
  ) {
    let layerData = tileData;
    let labelName = product['extraConfigs']['contourDataLabel'];
    for (let i = 0; i < layerData.features.length; i++) {
      const labelValue = layerData.features[i].properties[labelName];
      layerData.features[i].properties[labelName] = convertToUnit(
        labelValue,
        code,
        product['precision'],
        units[code]['unit']
      ).toFixed(product['precision']);
    }

    return layerData;
  }
}
