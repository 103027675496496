import mapboxgl from 'mapbox-gl';
import { removeLayerSource } from '../../HelperService';
import smooth from 'to-smooth';

export const drawBathymetryLayer = (
  map: mapboxgl.Map,
  bathymetryGeojson: any,
  showBathymetry: boolean,
  showBathymetryLabel: boolean,
  layerOrder: any
): void => {
  bathymetryGeojson.features.forEach((feature: any) => {
    feature.geometry.coordinates = smooth(feature.geometry.coordinates, {
      iteration: 5,
      factor: 0.75,
    });
  });
  map?.addSource('bathymetry', {
    type: 'geojson',
    data: bathymetryGeojson,
  });
  map?.addLayer(
    {
      id: 'bathymetry',
      type: 'line',
      source: 'bathymetry',
      layout: {
        visibility: showBathymetry ? 'visible' : 'none',
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#b4b5bd',
      },
    },
    layerOrder('bathymetry')
  );

  map?.addLayer({
    id: 'labels',
    type: 'symbol',
    source: 'bathymetry',
    layout: {
      visibility: showBathymetryLabel ? 'visible' : 'none',
      'symbol-placement': 'line',
      'text-font': ['Arial Unicode MS Regular'],
      'text-field': '{level}',
      'text-size': 14,
      'text-anchor': 'bottom',
    },
  });
};

export const toggleBathymetrylayers = (
  map: mapboxgl.Map,
  showBathymetry: boolean,
  showBathymetryLabel: boolean
) => {
  if (map) {
    if (map.getLayer('bathymetry')) {
      map.setLayoutProperty(
        'bathymetry',
        'visibility',
        showBathymetry ? 'visible' : 'none'
      );
      map.setLayoutProperty(
        'labels',
        'visibility',
        showBathymetryLabel ? 'visible' : 'none'
      );
    }
  }
};

export const drawEezLayer = (
  map: mapboxgl.Map,
  eezGeojson: any,
  showEez: boolean,
  layerOrder: any
): void => {
  removeLayerSource(map, 'eez', ['eez']);
  map?.addSource('eez', {
    type: 'geojson',
    data: eezGeojson,
  });
  map?.addLayer(
    {
      id: 'eez',
      type: 'line',
      source: 'eez',
      layout: {
        visibility: showEez ? 'visible' : 'none',
      },
      paint: {
        'line-color': '#54c0bc',
        'line-opacity': 0.8,
      },
    },
    layerOrder('eez')
  );
};

export const toggleEezLayer = (map: mapboxgl.Map, showEez: boolean) => {
  if (map) {
    if (map.getLayer('eez')) {
      map.setLayoutProperty('eez', 'visibility', showEez ? 'visible' : 'none');
    }
  }
};
