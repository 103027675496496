import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import LogoutIcon from '@mui/icons-material/Logout';
import { HeaderMenuItemProps } from '../../components/Header/HeaderTypes';
import { Header } from '../../components/Header/Header';
import { LogoSection } from '../../components/Header/LogoSection';
import { HeaderMenuSection } from '../../components/Header/HeaderMenuSection';
import AppDateRangePicker from '../../components/DateRangePicker/Component/DateRangePicker';
import moment from 'moment';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { IUUDashboardStyles } from './IUUDashboardStyles';
import { DatePickerRanges } from './DashboardConfig';
import { I18nKey } from '../../translations/I18nKey';

const IUUHeader: React.FC<any> = ({
  userName,
  seastarId,
  onDateRangeChange,
  modeList,
  mode,
  setMode,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [selectedStartDate, setStartDate] = useState(
    moment().subtract(1, 'days')
  );
  const [selectedEndDate, setEndDate] = useState(moment());

  const menuList: Array<HeaderMenuItemProps> = [
    {
      key: '1',
      icon: LogoutIcon,
      name: intl.formatMessage({
        id: I18nKey.AUTH_LOGOUT,
      }),
      itemClass: 'menu__iconFormat',
      onClick: () => navigate('/logout'),
    },
  ];
  const range = DatePickerRanges();

  const onDateChange = (fromDate: any, toDate: any) => {
    setStartDate(fromDate);
    setEndDate(toDate);
    onDateRangeChange([fromDate, toDate]);
  };

  return (
    <Header
      leftElement={
        <LogoSection
          route={intl.formatMessage({
            id: I18nKey.HEADER_IUU,
          })}
        ></LogoSection>
      }
      centerElement={
        <>
          <FormControl>
            <Select
              value={mode}
              onChange={(event) => {
                setMode(event.target.value);
              }}
              sx={{
                backgroundColor: '#ffffff !important',
                color: 'black !important',
                width: '160px !important',
                height: '40px',
                marginRight: '31px',
                marginTop: '18px',
                boxShadow: '0px 3px 6px #00000029',
              }}
            >
              {modeList.map((m: any) => {
                return (
                  <MenuItem value={m.key} key={m.key}>
                    <Box sx={{ display: 'flex' }}>
                      {intl.formatMessage({
                        id: I18nKey[m.labelId],
                      })}
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box sx={IUUDashboardStyles.dateRangePickerStyles}>
            <AppDateRangePicker
              defaultSelection={{
                start: selectedStartDate,
                end: selectedEndDate,
              }}
              ranges={range}
              format={'MMMM D, YYYY'}
              onChange={onDateChange}
              width={280}
              datePickerLabelStyles={
                IUUDashboardStyles.datePickerLabelExtraStyles
              }
            ></AppDateRangePicker>
          </Box>
        </>
      }
      rightElement={
        <HeaderMenuSection
          user={{ userName, seastarId }}
          menuList={menuList}
        ></HeaderMenuSection>
      }
    />
  );
};

export default IUUHeader;
