import { useEffect, useRef, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import _ from 'lodash';
import { isNil } from 'lodash';
import { useIndexedDB } from 'react-indexed-db';
import { useIntl } from 'react-intl';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { ColorBox } from '../../../../components/ColorBox/ColorBox';
import { LegendWrapper } from '../../../../components/LegendWrapper/LegendWrapper';
import { I18nKey } from '../../../../translations/I18nKey';
import { ProductUnitsContext } from '../../../Contexts/UnitsContext';
import { UserSettingsContext } from '../../../Contexts/UserSettingsContext';
import { FeaturesContext } from '../../FeaturesContext';
import {
  getUniqueProducts,
  isAnyProductSelected,
  toAntilog,
  toLog,
} from '../../HelperService';
import { ProductLegendStyles } from './ProductLegendStyles';
import { CursorData } from '../../../types';
import moment from 'moment';
import './legend.scss';
import { getLocation } from '../../Products/MapLayers/CursordataService';
import {
  RasterService,
  scalePartsConfig,
} from '../../Products/MapLayers/RasterService';
import { PREVIOUS_DATA_FETCH_DAYS } from '../../config';

export const SplitProductLegend: React.FC<any> = ({
  selectedProducts,
  userRegion,
  dateValue,
  cursorValue,
  tileData,
  onClose,
}) => {
  const intl = useIntl();
  const displayConfig = useContextSelector(
    FeaturesContext,
    (state) => state.displayConfig
  );
  const userData = useContextSelector(
    FeaturesContext,
    (state) => state.userData
  );
  const timeout = useRef(0);
  const units = useContextSelector(ProductUnitsContext, (state) => state);
  const { getByIndex } = useIndexedDB('cursorData');
  const [rasterProduct, setRasterProduct] = useState({
    productCode: '',
    unit: '',
  });
  const [rasterColorScale, setRasterColorStyle] = useState('');
  const [scale, setScale] = useState<any>([]);
  const [scalePointerPosition, setScalePointerPosition] = useState(0);
  const [selectedContourVectors, setSelectedContourVectors] = useState<any>({});
  const [selectedContourVectorsList, setSelectedContourVectorsList] = useState<
    string[][]
  >([]);
  const [cursorDataValues, setCursorDataValues] = useState<any>({});

  const closeProductsOnUnitChange = useContextSelector(
    UserSettingsContext,
    (state) => state.closeProductsOnUnitChange
  );

  useEffect(() => {
    if (!isNil(selectedProducts)) {
      setCursorDataValues({});
      setRasterData();
      setContourVectorData();
    }
  }, [selectedProducts]);

  useEffect(() => {
    if (isAnyProductSelected(selectedProducts) && closeProductsOnUnitChange) {
      onCloseProducts();
    }
  }, [closeProductsOnUnitChange]);

  const getDateAndDepth = (productData: any, dateVal: any) => {
    let dateValue = `${moment(dateVal).format('YYYYMMDD')}`;
    let isTimeProduct, isDepthProduct, date, depth;

    isTimeProduct = !_.isEmpty(productData.timeFrequency);
    date = isTimeProduct
      ? dateValue + sessionStorage.getItem('time')
      : dateValue;
    isDepthProduct = productData.depths[0] !== '-1';
    depth = isDepthProduct ? localStorage.getItem('depth') : '-1';
    return [date, depth];
  };

  const findCursorData = async (
    lngLat: { lat: number; lng: number },
    pCode: any,
    region: string,
    userData: any,
    units: any,
    getByIndex: any,
    getValueForPreviousDate?: boolean
  ) => {
    const [date, depth] = getDateAndDepth(userData[pCode], dateValue);
    let cursorDataValues: any = {};
    let data = await getByIndex('name', date + region + pCode + depth);
    if (data === undefined && getValueForPreviousDate) {
      let inputDate = moment(date, 'YYYYMMDD').toDate();
      let todaysDate = new Date();
      if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
        let newDate = moment()
          .subtract(PREVIOUS_DATA_FETCH_DAYS, 'd')
          .format('YYYYMMDD');
        let isTimeProduct = !_.isEmpty(userData[pCode].timeFrequency);
        let dateValue = isTimeProduct
          ? newDate + sessionStorage.getItem('time')
          : newDate;
        data = await getByIndex('name', dateValue + region + pCode + depth);
      }
    }
    if (data?.data) {
      const cursorDataPointLocal: any = getLocation(
        lngLat.lat,
        lngLat.lng,
        data.data
      ) as CursorData;
      let u = units[pCode].unitSection !== ' ' ? units[pCode].unit : '';
      cursorDataValues[(pCode.toLowerCase() as keyof CursorData) + u] =
        cursorDataPointLocal[pCode.toLowerCase() + u]?.toFixed(
          userData[pCode].precision
        );
      if (!_.isEmpty(cursorDataValues)) return cursorDataValues;
    }
    return {};
  };

  useEffect(() => {
    if (cursorValue) fetchCursordataValue(cursorValue);
  }, [cursorValue]);

  const fetchCursordataValue = (e: any) => {
    if (isAnyProductSelected(selectedProducts)) {
      let selectedProductList: any = getUniqueProducts(selectedProducts);
      if (selectedProductList.length) {
        if (timeout.current !== undefined) {
          window.clearTimeout(timeout.current);
        }
        timeout.current = window.setTimeout(() => {
          selectedProductList.forEach((pCode: string) => {
            if (
              userRegion &&
              displayConfig.data[userData[pCode]?.category]?.items[pCode]
                .cursorData
            ) {
              let cursorVal = findCursorData(
                e?.lngLat,
                pCode,
                userRegion,
                userData,
                units,
                getByIndex
              );
              cursorVal.then((value: any) => {
                setCursorDataValues({ ..._.merge(cursorDataValues, value) });
              });
            }
          });
        }, 100);
      }
    }
  };

  useEffect(() => {
    let u =
      units[rasterProduct.productCode]?.unitSection !== ' '
        ? units[rasterProduct.productCode]?.unit
        : '';
    setScalePointerPosition(
      RasterService.updatePointerPosition(
        userData[rasterProduct.productCode]?.scaleType,
        Number(cursorDataValues[rasterProduct.productCode.toLowerCase() + u])
      )
    );
  }, [cursorDataValues]);

  const setRasterData = () => {
    let rasterCode = selectedProducts.raster[0];
    if (rasterCode && displayConfig.data) {
      Object.values(displayConfig?.data).forEach((data: any) => {
        if (rasterCode) {
          if (data.items[rasterCode] && tileData[rasterCode].raster.tile) {
            let product = {
              productCode: rasterCode,
              unit: units[rasterCode]['unit'] || '',
            };
            setRasterProduct(product);
          }
        }
      });
    }
    if (!rasterCode) {
      setRasterProduct({
        productCode: '',
        unit: '',
      });
      setRasterColorStyle('');
      setScale([]);
    }
  };

  const setContourVectorData = () => {
    let prods: any = {};
    selectedProducts?.contour?.length > 0 &&
      selectedProducts?.contour.forEach((code: string) => {
        if (tileData[code]) {
          prods[code] = getDisplayData(code, 'contour');
        }
      });
    selectedProducts?.vector?.length > 0 &&
      selectedProducts?.vector.forEach((code: string) => {
        if (tileData[code]) {
          prods[code] = getDisplayData(code, 'vector');
        }
      });
    setSelectedContourVectors(prods);
    createLegendList(prods);
  };

  const getDisplayData = (code: string, type: string) => {
    return {
      color: tileData[code][type]?.color,
      unit: units[code]
        ? intl.formatMessage({
            id: I18nKey[`UNITS_${units[code]['unit'].toUpperCase()}`],
          })
        : '',
    };
  };

  const createLegendList = (prods: any) => {
    let products: string[][] = [];
    let p: string[] = [];
    let c = 0;
    let filteredProds = Object.keys(prods).filter((k) => _.isEmpty(prods[k]));
    let availableProds = _.omit(prods, filteredProds);
    _.sortBy(Object.keys(availableProds), (p) => {
      return displayConfig.data[userData[p]?.category]?.items[p]
        .productDialogOrder;
    }).forEach((x) => {
      p.push(x);
      c++;
      if (c % 2 === 0) {
        products.push(p);
        c = 0;
        p = [];
      }
    });
    if (c !== 0) {
      products.push(p);
    }
    if (filteredProds.length) {
      let list: any = [];
      filteredProds.forEach((p) => {
        list.push(p);
        if (list.length === 2) {
          products.push(list);
          list = [];
        }
      });
      if (list.length !== 0) {
        products.push(list);
      }
    }
    setSelectedContourVectorsList(products);
  };

  const onCloseProducts = () => {
    onClose();
  };

  return (
    <LegendWrapper
      title={
        rasterProduct.productCode &&
        intl.formatMessage({
          id: I18nKey[`PRODUCTS_${rasterProduct.productCode.toUpperCase()}`],
        })
      }
      hasSettingsButton={false}
      subTitle={
        rasterProduct.productCode &&
        rasterProduct.unit &&
        `(${intl.formatMessage({
          id: I18nKey[`UNITS_${rasterProduct.unit.toUpperCase()}`],
        })})`
      }
      wrapperStyle={ProductLegendStyles.wrapper}
      showSeparator={
        rasterProduct.productCode && selectedContourVectorsList.length > 0
          ? true
          : false
      }
      leftElement={
        rasterProduct?.productCode ? (
          <RasterElement
            rasterProduct={rasterProduct}
            displayConfig={displayConfig}
            tileData={tileData}
            cursorDataValues={cursorDataValues}
            userData={userData}
          ></RasterElement>
        ) : undefined
      }
      rightElement={
        selectedContourVectorsList?.length > 0 ? (
          <ContourVectorElement
            selectedContourVectorsList={selectedContourVectorsList}
            selectedContourVectors={selectedContourVectors}
            cursorDataValues={cursorDataValues}
          ></ContourVectorElement>
        ) : undefined
      }
      rightElementStyle={ProductLegendStyles}
      leftElementStyle={ProductLegendStyles}
      onCloseClick={onCloseProducts}
    ></LegendWrapper>
  );
};

const RasterElement: React.FC<any> = ({
  rasterProduct,
  displayConfig,
  tileData,
  cursorDataValues,
  userData,
}) => {
  let scaleLengthVal = 20;
  const [minMaxBoundaryVal, setMinMaxBoundaryVal] = useState(0);
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);
  const [diffVal, setDiffVal] = useState(0);

  const [rasterColorScale, setRasterColorStyle] = useState('');
  const [scale, setScale] = useState<any>([]);
  const [scalePointerPosition, setScalePointerPosition] = useState(0);

  useEffect(() => {
    setRasterData();
  }, []);

  useEffect(() => {
    let u =
      units[rasterProduct.productCode]?.unitSection !== ' '
        ? units[rasterProduct.productCode]?.unit
        : '';
    setScalePointerPosition(
      updatePointerPosition(
        userData[rasterProduct.productCode]?.scaleType,
        Number(cursorDataValues[rasterProduct.productCode.toLowerCase() + u])
      )
    );
  }, [cursorDataValues]);

  const setRasterColorLegend = (
    scaleType: string,
    min: any,
    max: any,
    precision: number | undefined
  ) => {
    let scale = [];
    // Logic here is inspired from https://numpy.org/doc/stable/reference/generated/numpy.logspace.html
    let minValue = getValue(scaleType || '', min || 0);
    setMinVal(minValue);
    let maxValue = getValue(scaleType || '', max || 0);
    setMaxVal(maxValue);
    let diffValue = maxValue - minValue;
    setDiffVal(diffValue);
    let parts = getParts(maxValue);
    for (let i = 0; i < parts; i++) {
      const value = maxValue - (i * diffValue) / (parts - 1);
      scale.push(
        scaleType === 'log10'
          ? +toAntilog(value).toFixed(precision)
          : +value.toFixed(precision)
      );
    }
    return min !== max ? scale.reverse() : [min];
  };

  const getParts = (maxValue: any) => {
    let parts = scalePartsConfig[Math.trunc(maxValue).toString().length]
      ? scalePartsConfig[Math.trunc(maxValue).toString().length]
      : 10;
    return parts;
  };

  const setRasterData = () => {
    let rasterCode = rasterProduct.productCode;
    if (rasterCode && displayConfig.data) {
      Object.values(displayConfig?.data).forEach((data: any) => {
        if (rasterCode) {
          if (data.items[rasterCode] && tileData[rasterCode].raster.tile) {
            let colorScale = RasterService.getSliderBackground(
              data.items[rasterCode].raster.colorScale,
              tileData[rasterCode].raster.tile.colorScaleType,
              'right'
            );
            setRasterColorStyle(colorScale);
            let scaleData = setRasterColorLegend(
              data.items[rasterCode].scaleType,
              tileData[rasterCode].raster.tile.min,
              tileData[rasterCode].raster.tile.max,
              data.items[rasterCode].precision
            );
            setScale([...scaleData]);
          }
        }
      });
    }
  };
  const getValue = (scale: string, value: number) => {
    if (scale === 'log10') return toLog(value);
    else return value;
  };
  const updatePointerPosition = (scaleType: string, cursorData: number) => {
    let boundaryVal = getValue(scaleType || '', cursorData);
    boundaryVal =
      boundaryVal < minVal
        ? minVal
        : boundaryVal > maxVal
        ? maxVal
        : boundaryVal;
    setMinMaxBoundaryVal(boundaryVal);
    let value =
      scaleLengthVal +
      (scaleLengthVal / diffVal) * ((boundaryVal - minVal) * 18.5);
    return value ? (value > 379 ? 379 : value) : 20;
  };

  const units = useContextSelector(ProductUnitsContext, (state) => state);
  let u =
    units[rasterProduct.productCode]?.unitSection !== ' '
      ? units[rasterProduct.productCode]?.unit
      : '';
  return (
    <>
      {rasterProduct.productCode && (
        <>
          <Box width={'100%'}>
            <Box
              sx={{
                ...ProductLegendStyles.scalePointer,
                ...{
                  left: `${Number(scalePointerPosition)}px`,
                },
              }}
            >
              <Box component="span" sx={ProductLegendStyles.scalePointerValue}>
                {cursorDataValues[
                  rasterProduct.productCode.toLowerCase() + u
                ] || 0}
              </Box>
            </Box>
            <Box
              sx={{
                ...ProductLegendStyles.scaleDisplay,
                ...{ backgroundImage: rasterColorScale },
              }}
            ></Box>
            <Box sx={ProductLegendStyles.scale}>
              <Stack direction="row" justifyContent="space-evenly">
                {scale.map((v: number, index: number) => {
                  return (
                    <Box key={index} sx={ProductLegendStyles.scaleValue}>
                      {v}
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

const ContourVectorElement: React.FC<any> = ({
  selectedContourVectorsList,
  selectedContourVectors,
  cursorDataValues,
}) => {
  const intl = useIntl();
  const units = useContextSelector(ProductUnitsContext, (state) => state);
  let cols =
    Object.keys(selectedContourVectors).filter(
      (k) => !_.isEmpty(selectedContourVectors[k])
    ).length * 3;
  const weatherProducts = Object.keys(selectedContourVectors).filter((k) =>
    _.isEmpty(selectedContourVectors[k])
  );

  let weatherNotificationProducts: any[][] = [];
  let normalProducts: any[][] = [];

  selectedContourVectorsList.forEach((pr: any) => {
    if (weatherProducts.length && weatherProducts.some((r) => pr.includes(r))) {
      let list: any = [];
      pr.forEach(() => {});
      if (list.length !== 0) {
        weatherNotificationProducts.push(list);
      }
    } else normalProducts.push(pr);
  });

  return (
    <>
      {normalProducts.length > 0 && (
        <Grid
          container
          direction="row"
          columns={
            weatherNotificationProducts.length
              ? cols + weatherNotificationProducts.length * 3
              : cols
          }
        >
          {normalProducts.map((pr: any) => {
            return (
              <Grid
                item
                container
                key={`${pr}col`}
                alignContent="baseline"
                sx={{
                  marginTop: '3px',
                  marginRight: '13px',
                  width: '100px',
                }}
              >
                <Grid item key={`${pr}row`}>
                  {pr.map((p: string) => {
                    let u = units[p]?.unitSection !== ' ' ? units[p]?.unit : '';

                    return (
                      <Stack
                        key={`${p}section`}
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{ marginTop: '10px' }}
                      >
                        <>
                          <ColorBox
                            key={p}
                            boxStyle={ProductLegendStyles.boxStyle}
                            boxText={`${intl.formatMessage({
                              id: I18nKey[`PRODUCT_CODE_${p}`],
                            })}`}
                            boxTextStyle={ProductLegendStyles.boxTextStyle}
                            color={selectedContourVectors[p].color}
                          ></ColorBox>
                          <Box
                            sx={{
                              display: 'flex',
                              color: '#FFFFFF',
                            }}
                          >
                            <Typography
                              sx={{ minWidth: '30px', fontSize: '14px' }}
                            >
                              {_.has(
                                cursorDataValues,
                                (p.toLowerCase() as keyof CursorData) + u
                              )
                                ? cursorDataValues[
                                    (p.toLowerCase() as keyof CursorData) + u
                                  ]
                                : cursorDataValues[p] || 0}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                              }}
                            >
                              ({selectedContourVectors[p].unit})
                            </Typography>
                          </Box>
                        </>
                      </Stack>
                    );
                  })}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};
