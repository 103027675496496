import {
  Backdrop,
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { MAPBOX_TILES } from '../config';
import '../Map.scss';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import moment from 'moment';
import { isEmpty, isNil } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import { FeaturesContext } from '../FeaturesContext';
import ProductClient from '../../../api/productAPIs';
import { getUserAttributes } from '../../../utils/auth';
import { UserSettingsContext } from '../../Contexts/UserSettingsContext';
import arrow1 from '../../../assets/icons/arrow1.png';
import arrow2 from '../../../assets/icons/arrow2.png';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import axios from 'axios';
import smooth from 'to-smooth';
import { useIndexedDB } from 'react-indexed-db';
import { mapCursorData } from '../Products/MapLayers/CursordataService';
import DatePickerV2 from '../../../components/DateRangePicker/Component/DatePickerV2';
import { SplitProductLegend } from './VerticalLegend/SplitProductLegend';
import {
  convert,
  isAnyProductSelected,
  removeLayerSource,
  updateTileDataByUnit,
} from '../HelperService';
import { layerOrder } from './SplitScreenService';
import { ProductUnitsContext } from '../../Contexts/UnitsContext';
import { ContourService } from '../Products/MapLayers/ContourService';
import { I18nKey } from '../../../translations/I18nKey';
import { Alert, Snackbar as SnackbarMUI } from '@mui/material';
import { RegionContext } from '../../Contexts/RegionContext';
import { getSelectedRegion } from '../../../utils/util';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { MapFooter } from '../MapFooter';
import { addGraticule } from '../Products/MapLayers/GraticuleService';

export const SplitView: React.FC<any> = () => {
  const intl = useIntl();
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN || '';
  const mapLeft = useRef<mapboxgl.Map | null>(null);
  const mapRight = useRef<mapboxgl.Map | null>(null);
  const dummyCursorRight = useRef<HTMLElement | null>(null);
  const dummyCursorLeft = useRef<HTMLElement | null>(null);
  const [date1, setDate1] = useState(moment(new Date()).toDate());
  const [date2, setDate2] = useState(moment(new Date()).toDate());

  const [rasterProduct1, setRasterProduct1] = useState<any>('');
  const [rasterProduct2, setRasterProduct2] = useState<any>('');
  const [cvProducts1, setCVProducts1] = useState<string[]>([]);
  const [cvProducts2, setCVProducts2] = useState<string[]>([]);
  const [loadingLeft, setLoadingLeft] = useState(false);
  const [loadingRight, setLoadingRight] = useState(false);
  const [alertLeft, setAlertLeft] = useState<any>({
    type: '',
    display: false,
    message: '',
  });
  const [alertRight, setAlertRight] = useState<any>({
    type: '',
    display: false,
    message: '',
  });

  const displayConfig = useContextSelector(
    FeaturesContext,
    (state) => state.displayConfig
  );
  const userData = useContextSelector(
    FeaturesContext,
    (state) => state.userData
  );
  const tileData = useContextSelector(
    FeaturesContext,
    (state) => state.tileData
  );
  const units = useContextSelector(ProductUnitsContext, (state) => state);

  const userRegion = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings.map?.regionsOfInterest
  );

  const { userName, seastarId } = getUserAttributes();
  const [tileDataLeft, setTileDataLeft] = useState<any>({});
  const [tileDataRight, setTileDataRight] = useState<any>({});
  const [mapLayersLeft, setMapLayersLeft] = useState<any>({
    raster: '',
    contour: [],
    vector: [],
  });
  const [mapLayersRight, setMapLayersRight] = useState<any>({
    raster: '',
    contour: [],
    vector: [],
  });
  const [selectedProductsLeft, setSelectedProductsLeft] = useState<any>({
    raster: [],
    contour: [],
    vector: [],
  });
  const [selectedProductsRight, setSelectedProductsRight] = useState<any>({
    raster: [],
    contour: [],
    vector: [],
  });
  const currentZoom = useRef(0);
  const regions = useContextSelector(RegionContext, (state) => state);
  const [cursorValue, setCursorValue] = useState<any>();
  const { getByIndex } = useIndexedDB('cursorData');
  const addToIndexedDB = useIndexedDB('cursorData').add;
  const [openLeft, setOpenLeft] = useState(false);
  const [openRight, setOpenRight] = useState(false);
  const headerTopValue = 78;
  const rasterProducts = ['SST', 'PLK', 'SSHA', 'CUR'];
  const contourVectorProducts = [
    'SST_contour',
    'PLK_contour',
    'SSHA_contour',
    'CUR_vector',
  ];

  useEffect(() => {
    if (!isEmpty(regions) && !isEmpty(userRegion)) {
      const region = getSelectedRegion(regions, userRegion);
      const latLong: any = [
        parseInt(region.center.split(',')[0]),
        parseInt(region.center.split(',')[1]),
      ];

      const defaultMap1 = new mapboxgl.Map({
        container: 'mapContainer1',
        style: MAPBOX_TILES,
        center: latLong,
        zoom: 4,
        minZoom: 1.2,
        maxZoom: 21,
      });
      defaultMap1.resize();
      defaultMap1.doubleClickZoom.disable();
      defaultMap1.dragRotate.disable();
      defaultMap1.touchZoomRotate.disable();
      const scale = new mapboxgl.ScaleControl({
        maxWidth: 100,
        unit: 'nautical',
      });
      defaultMap1?.addControl(scale, 'bottom-right');

      mapLeft.current = defaultMap1;

      const defaultMap2 = new mapboxgl.Map({
        container: 'mapContainer2',
        style: MAPBOX_TILES,
        center: latLong,
        zoom: 4,
        minZoom: 1.2,
        maxZoom: 21,
      });
      defaultMap2.resize();
      defaultMap2.doubleClickZoom.disable();
      defaultMap2.dragRotate.disable();
      defaultMap2.touchZoomRotate.disable();
      const scale1 = new mapboxgl.ScaleControl({
        maxWidth: 100,
        unit: 'nautical',
      });
      defaultMap2?.addControl(scale1, 'bottom-right');
      mapRight.current = defaultMap2;

      mapLeft.current.on('move', syncLeftToRight);
      mapRight.current.on('move', syncRightToLeft);

      dummyCursorRight.current = document.getElementById('dummy-cursor-right');
      dummyCursorLeft.current = document.getElementById('dummy-cursor-left');

      mapLeft.current.on('mousemove', setRightDummyCursor);
      mapLeft.current.on('load', () => {
        drawGraticule(mapLeft.current);
        mapLeft?.current?.on('wheel', () => {
          drawGraticule(mapLeft.current);
          drawGraticule(mapRight.current);
        });
      });

      // Hide dummy cursor on right map
      mapLeft.current.on('mouseleave', hideRightDummyCursor);

      // Track mouse movements on the right map and update the dummy cursor on the left map
      mapRight.current.on('mousemove', setLeftDummyCursor);
      mapRight.current.on('load', () => {
        drawGraticule(mapRight.current);
        mapRight?.current?.on('wheel', () => {
          drawGraticule(mapRight.current);
          drawGraticule(mapLeft.current);
        });
      });
      // Hide dummy cursor on left map
      mapRight.current.on('mouseleave', hideLeftDummyCursor);
    }
    return () => {
      mapLeft.current?.off('mousemove', setRightDummyCursor);
      mapRight.current?.off('mousemove', setLeftDummyCursor);
      mapLeft.current?.off('mouseleave', hideRightDummyCursor);
      mapRight.current?.off('mouseleave', hideLeftDummyCursor);
    };
  }, [userRegion, regions]);

  useEffect(() => {
    setSelectedProductsLeft(getSelectedProducts(mapLayersLeft));
  }, [mapLayersLeft]);

  useEffect(() => {
    setSelectedProductsRight(getSelectedProducts(mapLayersRight));
  }, [mapLayersRight]);

  useEffect(() => {
    refetchProducts(mapLayersLeft, mapLeft, 'left');
  }, [date1]);

  useEffect(() => {
    refetchProducts(mapLayersRight, mapRight, 'right');
  }, [date2]);

  useEffect(() => {
    let key = isAnyProductSelected(selectedProductsLeft);
    if (key && mapLeft.current) {
      mapLeft.current.on('mousemove', fetchCursordataValue);
    }
    return () => {
      mapLeft.current?.off('mousemove', fetchCursordataValue);
    };
  }, [mapLeft, selectedProductsLeft]);

  useEffect(() => {
    let key = isAnyProductSelected(selectedProductsRight);
    if (key && mapRight.current) {
      mapRight.current.on('mousemove', fetchCursordataValue);
    }
    return () => {
      mapRight.current?.off('mousemove', fetchCursordataValue);
    };
  }, [mapRight, selectedProductsLeft]);

  useEffect(() => {
    return () => {
      if (mapRight.current && mapLeft.current) {
        mapLeft.current.on('mousemove', fetchCursordataValue);
        mapRight.current.on('mousemove', fetchCursordataValue);
      }
    };
  }, [selectedProductsLeft, selectedProductsRight]);

  const hideLeftDummyCursor = () => {
    if (dummyCursorLeft.current) dummyCursorLeft.current.style.display = 'none';
  };

  const hideRightDummyCursor = () => {
    if (dummyCursorRight.current)
      dummyCursorRight.current.style.display = 'none';
  };

  const setLeftDummyCursor = (e: any) => {
    if (mapLeft.current && mapRight.current) {
      setCursorValue(e);
      const rightBounds = mapRight.current
        .getContainer()
        .getBoundingClientRect();
      const leftBounds = mapLeft.current.getContainer().getBoundingClientRect();
      // Calculate the position relative to the right map
      const mouseX = e.originalEvent.clientX - rightBounds.left;
      const mouseY = e.originalEvent.clientY - rightBounds.top;

      // Hide dummy cursor on the active map (right) and show on the left
      if (dummyCursorRight.current) {
        dummyCursorRight.current.style.display = 'none';
      }
      const cursorX = leftBounds.left + mouseX - 8;
      const cursorY = leftBounds.top + mouseY - headerTopValue - 10;
      showDummyCursor(dummyCursorLeft, cursorX, cursorY);
    }
  };

  const setRightDummyCursor = (e: any) => {
    if (mapLeft.current && mapRight.current) {
      setCursorValue(e);
      const leftBounds = mapLeft.current.getContainer().getBoundingClientRect();

      const rightBounds = mapRight.current
        .getContainer()
        .getBoundingClientRect();
      // Calculate the position relative to the left map
      const mouseX = e.originalEvent.clientX - leftBounds.left;
      const mouseY = e.originalEvent.clientY - leftBounds.top;

      // Hide dummy cursor on the active map (left) and show on the right
      if (dummyCursorLeft.current) {
        dummyCursorLeft.current.style.display = 'none';
      }
      const cursorX = mouseX - 8;
      const cursorY = rightBounds.top + mouseY - headerTopValue - 10;
      showDummyCursor(dummyCursorRight, cursorX, cursorY);
    }
  };

  const refetchProducts = (layers: any, map: any, side: string) => {
    if (layers.raster) {
      let code = layers.raster.replace('raster', '');
      removeMapLayer(map, code, 'raster');
      removeMapLayerId('raster', layers.raster, side);
      getMapTileData(code, 'raster', false, map, side);
    }
    if (layers.contour.length) {
      layers.contour.forEach((l: string) => {
        let code = l.replace('contour', '');
        removeMapLayer(map, code, 'contour');
        removeMapLayerId('contour', l, side);
        getMapTileData(code, 'contour', false, map, side);
      });
    }
    if (layers.vector.length) {
      layers.vector.forEach((l: string) => {
        let code = l.replace('vector', '');
        removeMapLayer(map, code, 'vector');
        removeMapLayerId('vector', l, side);
        getMapTileData(code, 'vector', false, map, side);
      });
    }
  };

  const fetchCursordataValue = (e: any) => {
    setCursorValue(e);
  };

  const syncRightToLeft = () => {
    if (mapRight.current && mapLeft.current) {
      const center = mapRight.current.getCenter();
      const zoom = mapRight.current.getZoom();
      const bearing = mapRight.current.getBearing();
      const pitch = mapRight.current.getPitch();

      mapLeft.current.off('move', syncLeftToRight);
      mapLeft.current.setCenter(center);
      mapLeft.current.setZoom(zoom);
      mapLeft.current.setBearing(bearing);
      mapLeft.current.setPitch(pitch);
      mapLeft.current.on('move', syncLeftToRight);
    }
  };

  const syncLeftToRight = () => {
    if (mapLeft.current && mapRight.current) {
      const center = mapLeft.current.getCenter();
      const zoom = mapLeft.current.getZoom();
      const bearing = mapLeft.current.getBearing();
      const pitch = mapLeft.current.getPitch();

      mapRight.current.off('move', syncRightToLeft);
      mapRight.current.setCenter(center);
      mapRight.current.setZoom(zoom);
      mapRight.current.setBearing(bearing);
      mapRight.current.setPitch(pitch);
      mapRight.current.on('move', syncRightToLeft);
    }
  };
  const drawGraticule = (map: any) => {
    if (map) {
      removeLayerSource(map, 'graticule', ['graticule', 'graticuleLabels']);
      currentZoom.current = 0;
      currentZoom.current = addGraticule(
        map,
        currentZoom.current,
        map?.getZoom() || 0
      );
    }
  };

  // Helper function to show and position the dummy cursor on the opposite map
  const showDummyCursor = (
    dummyCursor: any,
    // mapBounds: any,
    cursorX: any,
    cursorY: any
  ) => {
    dummyCursor.current.style.left = `${cursorX}px`;
    dummyCursor.current.style.top = `${cursorY}px`;
    dummyCursor.current.style.display = 'block';
    dummyCursor.current.style.zIndex = 70;
  };

  const onChange = (
    dateSelected: Date | null,
    action: 'back' | 'forward' | null,
    setDateFn: any,
    mapSide: any
  ) => {
    if (!dateSelected) return;

    const newDate = new Date(dateSelected);
    if (action === 'back') {
      newDate.setDate(newDate.getDate() - 1);
    } else if (action === 'forward') {
      newDate.setDate(newDate.getDate() + 1);
    }
    setDateFn(newDate);
  };

  const handleChangeSelection = (
    event: any,
    setProductFn: any,
    map: any,
    mapSide: any
  ) => {
    const {
      target: { value },
    } = event;
    let list = typeof value === 'string' ? value.split(',') : value;
    setProductFn(list);
    if (mapSide === 'left') {
      localStorage.setItem('leftProducts', list.join());
      setOpenLeft(false);
    } else {
      localStorage.setItem('rightProducts', list.join());
      setOpenRight(false);
    }
    let layers = mapSide === 'left' ? mapLayersLeft : mapLayersRight;
    let curCode = '';
    if (layers.contour.length) {
      layers.contour.forEach((l: string) => {
        if (!list.includes(l.replace('contour', '_contour'))) {
          removeMapLayer(map, l.replace('contour', ''), 'contour');
          removeMapLayerId('contour', l, mapSide);
        } else curCode = l.replace('contour', '');
      });
    }
    if (layers.vector.length) {
      layers.vector.forEach((l: string) => {
        if (!list.includes(l.replace('vector', '_vector'))) {
          removeMapLayer(map, l.replace('vector', ''), 'vector');
          removeMapLayerId('vector', l, mapSide);
        } else curCode = l.replace('vector', '');
      });
    }
    list.forEach((v: any) => {
      let code = v.split('_')[0];
      let type = v.split('_')[1];
      if (curCode !== code) {
        getMapTileData(code, type, false, map, mapSide);
      }
    });
  };

  const handleRasterChange = (
    event: SelectChangeEvent,
    setProductFn: any,
    map: any,
    mapSide: any
  ) => {
    const {
      target: { value },
    } = event;
    setProductFn(event.target.value as string);
    if (
      (mapSide === 'left' && mapLayersLeft.raster) ||
      (mapSide === 'right' && mapLayersRight.raster)
    ) {
      let code =
        mapSide === 'left'
          ? mapLayersLeft.raster.replace('raster', '')
          : mapLayersRight.raster.replace('raster', '');
      removeMapLayer(map, code, 'raster');
      removeMapLayerId(
        'raster',
        mapSide === 'left' ? mapLayersLeft.raster : mapLayersRight.raster,
        mapSide
      );
    }
    getMapTileData(value, 'raster', false, map, mapSide);
  };

  const getDateAndDepth = (productData: any, dateVal: any) => {
    let dateValue = `${moment(dateVal).format('YYYYMMDD')}`;
    let isTimeProduct, isDepthProduct, date, depth;

    isTimeProduct = !_.isEmpty(productData.timeFrequency);
    date = isTimeProduct
      ? dateValue + sessionStorage.getItem('time')
      : dateValue;
    isDepthProduct = productData.depths[0] !== '-1';
    depth = isDepthProduct ? localStorage.getItem('depth') : '-1';
    return [date, depth];
  };

  const fetchCursorDataValues = (selectedProductCode: any, mapSide: string) => {
    const [date, depth] = getDateAndDepth(
      userData[selectedProductCode],
      mapSide === 'left' ? date1 : date2
    );
    displayConfig.data[userData[selectedProductCode].category].items[
      selectedProductCode
    ].cursorData &&
      date &&
      populateCursorData(
        userName,
        date,
        userRegion || '',
        selectedProductCode,
        depth || '-1'
      );
  };

  const populateCursorData = (
    userName: string,
    date: string,
    region: string,
    code: string,
    depth: string
  ) => {
    getByIndex('name', date + region + code + depth).then((data: any) => {
      if (isNil(data) && code !== 'HAB') {
        getcursorDataURL(userName, date, region, code, parseInt(depth));
      }
    });
  };

  const getcursorDataURL = async (
    userName: string,
    date: string,
    region: string,
    productCode: string,
    depth: number
  ) => {
    try {
      const url = await ProductClient.getCursorData(
        userName,
        date,
        region,
        productCode,
        depth,
        'json'
      );
      getCursorDataJSON(url, date, region, productCode, depth);
    } catch (error) {
      console.log(error);
    }
  };

  const getCursorDataJSON = async (
    url: string,
    date: string,
    region: string,
    productCode: string,
    depth: number
  ) => {
    try {
      const data = await axios.get<any>(url);
      let cursorData;
      if (!isEmpty(data.data)) {
        cursorData = mapCursorData(data.data, productCode);
        addToIndexedDB({
          name: date + region + productCode + depth,
          data: cursorData,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMapTileData = (
    productCode: any,
    productType: any,
    fetchCursorData: boolean,
    map: any,
    mapSide: any
  ) => {
    const [date, depth] = getDateAndDepth(
      userData[productCode],
      mapSide === 'left' ? date1 : date2
    );
    date && getMapTile(productCode, productType, depth, {}, date, map, mapSide);

    fetchCursorDataValues(productCode, mapSide);
  };

  const getMapTile = (
    pCode: any,
    pType: string,
    depth: any,
    pTileData: any,
    iDate: string,
    map: any,
    mapSide: any
  ) => {
    const inputTileData = {
      productCode: pCode,
      productType: pType,
      depth: parseInt(depth),
      defaultTile: isEmpty(pTileData),
      pTileData,
    };
    generateMapTileAPICall(
      userName,
      iDate || '',
      userRegion,
      inputTileData,
      map,
      mapSide
    );
  };

  const getDataFromPresignedURL = async (
    urlData: any,
    map: any,
    mapSide: any
  ) => {
    try {
      const data = await axios.get<any>(urlData.url);
      setTileResponse(urlData.tileInputData, data, map, mapSide);
    } catch (error) {
      console.error(error);
      if (mapSide === 'left') setLoadingLeft(false);
      else setLoadingRight(false);
    }
  };

  const generateMapTileAPICall = async (
    userName: string,
    date: string,
    region: any,
    tileDetails: any,
    map: any,
    mapSide: any
  ) => {
    try {
      if (mapSide === 'left') setLoadingLeft(true);
      else setLoadingRight(true);
      const data = await ProductClient.generateMapTile(
        userName,
        date || '',
        region || '',
        tileDetails.productCode,
        tileDetails.productType,
        tileDetails.depth,
        tileDetails.defaultTile,
        tileDetails.pTileData
      );
      if (tileDetails.productType === 'raster') {
        let tileData = _.cloneDeep(data[0].raster);
        setTileResponse(tileDetails, tileData, map, mapSide);
        if (mapSide === 'left') setLoadingLeft(false);
        else setLoadingRight(false);
      } else {
        getDataFromPresignedURL(
          {
            url: data,
            tileInputData: tileDetails,
          },
          map,
          mapSide
        );
        if (mapSide === 'left') setLoadingLeft(false);
        else setLoadingRight(false);
      }
    } catch (error: any) {
      if (mapSide === 'left') {
        if (tileDetails.productType === 'raster') setRasterProduct1(() => '');
        else {
          let products = localStorage.getItem('leftProducts');
          if (products && products.length) {
            let prods = products.split(',').filter((p: any) => {
              if (p !== `${tileDetails.productCode}_${tileDetails.productType}`)
                return p;
            });
            localStorage.setItem('leftProducts', prods.join());
            setCVProducts1(prods);
          }
        }
      } else {
        if (tileDetails.productType === 'raster') setRasterProduct2(() => '');
        else {
          let products = localStorage.getItem('rightProducts');
          if (products && products.length) {
            let prods = products.split(',').filter((p: any) => {
              if (p !== `${tileDetails.productCode}_${tileDetails.productType}`)
                return p;
            });
            localStorage.setItem('rightProducts', prods.join());
            setCVProducts2(prods);
          }
        }
      }
      if (mapSide === 'left') setLoadingLeft(false);
      else setLoadingRight(false);

      enqueueSnackbar(
        `${intl.formatMessage({
          id: I18nKey.PRODUCTS_NO_DATA_MSG_2,
        })}${tileDetails.productCode}`,
        {
          variant: 'error',
          autoHideDuration: 3000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: 'top',
            horizontal: mapSide === 'left' ? 'left' : 'right',
          },
        }
      );
    }
  };

  const addMapLayerId = (key: string, layerId: string, mapSide: string) => {
    let layers: any = mapSide === 'left' ? mapLayersLeft : mapLayersRight;
    if (typeof layers[key] === 'string') {
      layers[key] = layerId;
    } else {
      if (!layers[key].includes(layerId)) layers[key].push(layerId);
    }
    if (mapSide === 'left')
      setMapLayersLeft((prev: any) => {
        return { ...layers };
      });
    else
      setMapLayersRight((prev: any) => {
        return { ...layers };
      });
  };

  const removeMapLayerId = (key: string, layerId: string, mapSide: any) => {
    if (layerId) {
      let layers: any = mapSide === 'left' ? mapLayersLeft : mapLayersRight;
      if (typeof layers[key] === 'string') {
        layers[key] = '';
      } else if (layers[key] && layers[key].length)
        layers[key] = layers[key].filter((l: string) => l !== layerId);
      if (mapSide === 'left')
        setMapLayersLeft((prev: any) => {
          return { ...layers };
        });
      else
        setMapLayersRight((prev: any) => {
          return { ...layers };
        });
    }
  };

  const setTileResponse = (
    tileInputData: any,
    data: any,
    map: any,
    mapSide: any
  ) => {
    switch (tileInputData.productType) {
      case 'raster':
        setRasterTile(tileInputData, data, map, mapSide);
        break;
      case 'contour':
        setContourTile(tileInputData, data, map, mapSide);
        break;
      case 'vector':
        setVectorTile(tileInputData, data, map, mapSide);
        break;
      default:
        console.log('Product type out of scope');
        break;
    }
  };

  const removeMapLayer = (map: any, code: string, type: string) => {
    let layerKey = code + type;
    let layers = [layerKey];
    type === 'contour' && layers.push(code + 'contourLabels');
    if (type === 'weather' && code === 'FNT')
      layers = [
        ...layers,
        code + 'weatherIcons',
        code + 'weathericonsOccluded',
      ];
    if (type === 'weather' && code === 'STORM')
      layers = [...layers, code + 'weatherIcons'];
    if (map && map.current.getSource(layerKey)) {
      layers.forEach((l) => {
        if (map.current?.getLayer(l)) map.current.removeLayer(l);
      });
      map.current.removeSource(layerKey);
    }
  };

  const setRasterTile = (
    tileInputData: any,
    data: any,
    map: any,
    mapSide: any
  ) => {
    if (!isEmpty(tileInputData)) {
      let tileDataOb = Object.assign({}, data);
      tileDataOb = addUserData(
        tileInputData.productCode,
        tileInputData.productType
      );

      tileDataOb[tileInputData.productCode][tileInputData.productType] = data;
      [
        tileDataOb[tileInputData.productCode][tileInputData.productType].tile
          .min,
        tileDataOb[tileInputData.productCode][tileInputData.productType].tile
          .max,
      ] = convert(
        tileDataOb[tileInputData.productCode][tileInputData.productType].tile
          .min,
        tileDataOb[tileInputData.productCode][tileInputData.productType].tile
          .max,
        tileInputData.productCode,
        userData[tileInputData.productCode].precision,
        units[tileInputData.productCode].unit
      );
      removeMapLayer(map, tileInputData.productCode, tileInputData.productType);

      addRasterLayer(
        tileInputData.productCode,
        tileInputData.productType,
        tileDataOb[tileInputData.productCode][tileInputData.productType],
        map,
        mapSide
      );
      if (mapSide === 'left')
        setTileDataLeft((prev: any) => {
          return { ...prev, ...tileDataOb };
        });
      else
        setTileDataRight((prev: any) => {
          return { ...prev, ...tileDataOb };
        });
    }
  };

  const addUserData = (pCode: string, pType: string) => {
    let tileDataOb = Object.assign({}, tileData);
    tileDataOb[pCode][pType] = _.merge(
      tileDataOb[pCode][pType],
      userData[pCode][pType].user
    );
    return tileDataOb;
  };

  const setContourTile = (
    tileInputData: any,
    data: any,
    map: any,
    mapSide: string
  ) => {
    if (!isEmpty(tileInputData)) {
      let tileDataOb = Object.assign({}, data);
      tileDataOb = addUserData(
        tileInputData.productCode,
        tileInputData.productType
      );
      tileDataOb = updateTileDataByUnit(
        tileDataOb,
        tileInputData.productCode,
        tileInputData.productType,
        'min',
        'max',
        tileDataOb[tileInputData.productCode][tileInputData.productType].min,
        tileDataOb[tileInputData.productCode][tileInputData.productType].max,
        userData[tileInputData.productCode].precision,
        units[tileInputData.productCode].unit
      );

      removeMapLayer(map, tileInputData.productCode, tileInputData.productType);
      tileDataOb[tileInputData.productCode][tileInputData.productType]['data'] =
        data.data;

      addContourLayer(
        tileInputData.productCode,
        tileDataOb[tileInputData.productCode][tileInputData.productType],
        map,
        mapSide
      );
      if (mapSide === 'left')
        setTileDataLeft((prev: any) => {
          return { ...prev, ...tileDataOb };
        });
      else
        setTileDataRight((prev: any) => {
          return { ...prev, ...tileDataOb };
        });
    }
  };

  const setVectorTile = (
    tileInputData: any,
    data: any,
    map: any,
    mapSide: string
  ) => {
    if (!isEmpty(tileInputData)) {
      let tileDataOb = Object.assign({}, data);
      tileDataOb = addUserData(
        tileInputData.productCode,
        tileInputData.productType
      );
      if (data) {
        tileDataOb[tileInputData.productCode][tileInputData.productType][
          'data'
        ] = data?.data;
      }

      removeMapLayer(map, tileInputData.productCode, tileInputData.productType);
      addVectorLayer(
        tileInputData.productCode,
        tileDataOb[tileInputData.productCode][tileInputData.productType],
        map,
        mapSide
      );
      if (mapSide === 'left')
        setTileDataLeft((prev: any) => {
          return { ...prev, ...tileDataOb };
        });
      else
        setTileDataRight((prev: any) => {
          return { ...prev, ...tileDataOb };
        });
    }
  };

  const addRasterLayer = (
    code: string,
    type: string,
    tileData: any,
    map: any,
    mapSide: any
  ) => {
    const layerKey = code + type;

    addMapLayerId('raster', layerKey, mapSide);
    map?.current.addSource(layerKey, {
      type: 'image',
      coordinates: tileData.data?.coordinates?.map((p: any) => {
        return p.map((i: any) => {
          return Number(i);
        });
      }),
      url: tileData.tile?.url[0],
    });
    map?.current.addLayer(
      {
        id: layerKey,
        type: 'raster',
        source: layerKey,
      },
      layerOrder(
        'raster',
        map.current,
        mapSide === 'left' ? mapLayersLeft : mapLayersRight
      )
    );
  };

  const addContourLayer = (
    code: string,
    tileData: any,
    map: any,
    mapSide: string
  ) => {
    const layerKey = code + 'contour';
    let product: any = {};
    Object.values(displayConfig.data).forEach((data: any) => {
      if (data.items[code]) product = data.items[code];
    });
    const layerData = ContourService.convertToCurrentUnit(
      code,
      tileData.data,
      product,
      units
    );

    layerData.features.forEach((feature: any) => {
      feature.geometry.coordinates = smooth(feature.geometry.coordinates, {
        iteration: 5,
        factor: 0.75,
      });
    });
    addMapLayerId('contour', layerKey, mapSide);
    if (!map.current.getSource(layerKey)) {
      map.current.addSource(layerKey, {
        type: 'geojson',
        data: layerData,
      });
      map.current.addLayer(
        {
          id: layerKey,
          type: 'line',
          source: layerKey,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': tileData?.color,
          },
        },
        layerOrder(
          'contour',
          map.current,
          mapSide === 'left' ? mapLayersLeft : mapLayersRight
        )
      );
      map.current.addLayer(
        {
          id: code + 'contourLabels',
          type: 'symbol',
          source: layerKey,
          layout: {
            'symbol-placement': 'line',
            'text-font': ['Arial Unicode MS Regular'],
            'text-field': `{${product['extraConfigs']['contourDataLabel']}}`,
            'text-size': 14,
            'text-anchor': 'bottom',
          },
          paint: {
            'text-color': tileData?.color,
          },
        },
        layerOrder(
          'contour',
          map.current,
          mapSide === 'left' ? mapLayersLeft : mapLayersRight
        )
      );
    }
  };

  const addVectorLayer = (
    code: string,
    tileData: any,
    map: any,
    mapSide: string
  ) => {
    let minVelocity = 0;
    let maxVelocity = 0;
    let geojsonData: { features: any; type?: string };

    let vectorDataArray = tileData.data?.split('\n');
    vectorDataArray?.shift();
    geojsonData = { type: 'FeatureCollection', features: [] };
    vectorDataArray?.forEach((item: string) => {
      const element = item.split(',').map(Number);
      if (element[0] < minVelocity) {
        minVelocity = element[0];
      }
      if (element[0] > maxVelocity) {
        maxVelocity = element[0];
      }
      geojsonData.features.push({
        type: 'Feature',
        properties: { direction: element[1], velocity: element[0] },
        geometry: { type: 'Point', coordinates: [element[3], element[2]] },
      });
    });

    drawVector(map, code, tileData, displayConfig, mapSide, {
      data: geojsonData,
      minVelocity: minVelocity,
      maxVelocity: maxVelocity,
    });
  };

  const drawVector = (
    map: any,
    code: string,
    tileData: any,
    displayConfig: any,
    mapSide: string,
    clusterData: { data: any; minVelocity?: number; maxVelocity?: number }
  ) => {
    let product: any = {};
    Object.values(displayConfig.data).forEach((data: any) => {
      if (data.items[code]) product = data.items[code];
    });
    const layerKey = code + 'vector';
    addMapLayerId('vector', layerKey, mapSide);
    const currentGeoJson = clusterData.data;
    if (!map.current?.getSource(layerKey)) {
      map.current?.addSource(layerKey, {
        type: 'geojson',
        data: currentGeoJson,
        cluster: true,
        clusterMaxZoom: 7,
        clusterRadius: 30,
        clusterProperties: {
          velocity: ['max', ['get', 'velocity']],
          direction: ['min', ['get', 'direction']],
        },
      });

      const imageStyle =
        tileData.style === 'arrow_right_alt' ? 'arrow2' : 'arrow1';
      const color = tileData.color;
      const size = tileData.size;
      if (product.extraConfigs.vectorIcon === 'arrow') {
        addCurrentsImageLayer(
          map,
          product,
          {
            imageStyle,
            color,
            size,
            clusterData,
          },
          mapSide
        );
      }
    }
  };

  const addCurrentsImageLayer = (
    map: any,
    product: any,
    currentsData: {
      imageStyle: any;
      color: any;
      size: number;
      clusterData: any;
    },
    mapSide: any
  ) => {
    const layerKey = product.code + 'vector';
    if (map) {
      if (!map.current?.hasImage(currentsData.imageStyle)) {
        map.current?.loadImage(
          currentsData.imageStyle === 'arrow1' ? arrow1 : arrow2,
          (error: any, image: any) => {
            if (error) throw error;
            image &&
              map.current?.addImage(currentsData.imageStyle, image, {
                sdf: true,
              });
          }
        );
      }

      map.current?.addLayer({
        id: layerKey,
        type: 'symbol',
        source: layerKey,
        layout: {
          'icon-image': currentsData.imageStyle,
          'icon-size': [
            'interpolate',
            ['linear'],
            ['get', 'velocity'],
            currentsData.clusterData.minVelocity,
            0.4 + (currentsData.size / 100) * 1,
            currentsData.clusterData.maxVelocity,
            1 + (currentsData.size / 100) * 5,
          ],
          'icon-rotate': ['get', 'direction'],
          'icon-anchor': 'top',
          'icon-rotation-alignment': 'map',
        },
        paint: {
          'icon-color': currentsData.color,
        },
      });
    }
  };

  const getSelectedProducts = (mapLayers: any) => {
    let selectedProducts: any = { raster: [], contour: [], vector: [] };
    if (mapLayers.raster) {
      let code = mapLayers.raster.replace('raster', '');
      selectedProducts['raster'] = [code];
    }
    if (mapLayers.contour.length) {
      mapLayers.contour.forEach((l: string) => {
        let code = l.replace('contour', '');
        selectedProducts['contour'].push(code);
      });
    }
    if (mapLayers.vector.length) {
      mapLayers.vector.forEach((l: string) => {
        let code = l.replace('vector', '');
        selectedProducts['vector'].push(code);
      });
    }
    return selectedProducts;
  };

  const onCloseProducts = (mapLayers: any, map: any, side: string) => {
    if (mapLayers.raster) {
      let code = mapLayers.raster.replace('raster', '');
      removeMapLayer(map, code, 'raster');
      removeMapLayerId('raster', mapLayers.raster, side);
    }
    if (mapLayers.contour.length) {
      mapLayers.contour.forEach((l: string) => {
        let code = l.replace('contour', '');
        removeMapLayer(map, code, 'contour');
        removeMapLayerId('contour', l, side);
      });
    }
    if (mapLayers.vector.length) {
      mapLayers.vector.forEach((l: string) => {
        let code = l.replace('vector', '');
        removeMapLayer(map, code, 'vector');
        removeMapLayerId('vector', l, side);
      });
    }
    if (side === 'left') {
      setRasterProduct1(() => '');
      setCVProducts1([]);
      setMapLayersLeft({
        raster: '',
        contour: [],
        vector: [],
      });
    } else {
      setRasterProduct2(() => '');
      setCVProducts2([]);
      setMapLayersRight({
        raster: '',
        contour: [],
        vector: [],
      });
    }
  };

  const onClose = (mapSide: string) => {
    let ob = {
      type: '',
      display: false,
      message: '',
    };
    if (mapSide === 'left') setAlertLeft(ob);
    else setAlertRight(ob);
  };

  const clearAlert = (timeout: number, mapSide: string) => {
    setTimeout(() => {
      onClose(mapSide);
    }, timeout);
  };

  const setAlertMessage = (
    message: string,
    type: string,
    mapSide: string,
    clearTimeout: number = 5000
  ) => {
    if (mapSide === 'left')
      setAlertLeft({
        type,
        display: true,
        message,
      });
    else
      setAlertRight({
        type,
        display: true,
        message,
      });
    clearAlert(clearTimeout, mapSide);
  };

  return (
    <>
      <Stack direction={'row'}>
        <SnackbarProvider maxSnack={4}>
          <Box
            id="mapContainer1"
            className="mapboxgl-canvas1"
            sx={{
              border: '1px black solid',
              top: `${headerTopValue}px`,
            }}
          >
            <div id="dummy-cursor-left" className="crosshair"></div>
            <Box
              sx={{
                width: '100%',
                position: 'absolute',
                height: '80px',
                zIndex: '30 !important',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              onMouseOver={(e) => {
                hideLeftDummyCursor();
              }}
            >
              <Box sx={{ margin: '0px 10px 0px 10px' }}>
                <FormControl
                  variant="outlined"
                  sx={{
                    backgroundColor: '#ffffff',
                    color: 'black !important',
                    height: '45px',
                    minWidth: 120,
                    marginRight: '20px',
                    marginTop: '11px !important',
                    borderRadius: '4px',
                    borderTop: '12px solid white',
                    boxShadow:
                      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <InputLabel
                    id="raster1"
                    shrink={rasterProduct1 !== ''}
                    sx={{ color: 'black !important', marginTop: '-8px' }}
                  >
                    Raster
                  </InputLabel>
                  <Select
                    labelId="raster1"
                    id="rasterLeft"
                    label="Raster"
                    value={rasterProduct1}
                    sx={{ marginTop: '-10px' }}
                    onChange={(e: any) =>
                      handleRasterChange(e, setRasterProduct1, mapLeft, 'left')
                    }
                  >
                    {rasterProducts.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  sx={{
                    backgroundColor: '#ffffff',
                    color: 'black !important',
                    height: '45px',
                    width: 200,
                    marginTop: '11px !important',
                    borderRadius: '4px',
                    borderTop: '12px solid white',
                    boxShadow:
                      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <InputLabel
                    id="contourVector1"
                    shrink={cvProducts1.length !== 0}
                    sx={{ color: 'black !important', marginTop: '-8px' }}
                  >
                    Contour/Vector
                  </InputLabel>
                  <Select
                    label="Contour/Vector"
                    labelId="contourVector1"
                    id="cvLeft"
                    multiple
                    open={openLeft}
                    onOpen={() => setOpenLeft(true)}
                    onClose={() => setOpenLeft(false)}
                    sx={{ marginTop: '-10px' }}
                    value={cvProducts1}
                    onChange={(e: any) =>
                      handleChangeSelection(e, setCVProducts1, mapLeft, 'left')
                    }
                    renderValue={(selected) =>
                      selected.map((s) => s.split('_')[0]).join(', ')
                    }
                  >
                    {contourVectorProducts.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={cvProducts1.includes(name)} />
                        <ListItemText primary={name.split('_')[0]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ margin: '0px 10px 0px 10px' }}>
                <Card
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '56px',
                    width: '225px',
                    marginTop: '11px',
                    marginLeft: '20px',
                  }}
                >
                  <NavigateBeforeIcon
                    fontSize="large"
                    onClick={() =>
                      onChange(date1 || null, 'back', setDate1, 'left')
                    }
                    style={{ cursor: 'pointer', paddingLeft: '3px' }}
                  />
                  <DatePickerV2
                    defaultSelection={date1}
                    format="MM/dd/yyyy"
                    onChange={(date: Date | undefined) =>
                      onChange(date || date1!, null, setDate1, 'left')
                    }
                  />
                  <NavigateNextIcon
                    fontSize="large"
                    onClick={() =>
                      onChange(date1 || null, 'forward', setDate1, 'left')
                    }
                    style={{ cursor: 'pointer', paddingRight: '3px' }}
                  />
                </Card>
              </Box>
            </Box>
            {isAnyProductSelected(selectedProductsLeft) ? (
              <SplitProductLegend
                side={'left'}
                selectedProducts={selectedProductsLeft}
                cursorValue={cursorValue}
                dateValue={date1}
                tileData={tileDataLeft}
                userRegion={userRegion}
                onClose={() => {
                  onCloseProducts(mapLayersLeft, mapLeft, 'left');
                }}
              />
            ) : null}
            {alertLeft.display && (
              <SnackbarMUI
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertLeft.display}
                sx={{ position: 'absolute' }}
                onClose={() => onClose('left')}
              >
                <Alert
                  onClose={() => onClose('left')}
                  severity={alertLeft.type}
                  sx={{ width: '100%' }}
                >
                  {alertLeft.message}
                </Alert>
              </SnackbarMUI>
            )}
            {loadingLeft && (
              <Backdrop
                sx={{ color: '#ffffff', zIndex: '999 !important' }}
                open={loadingLeft}
              >
                <Container
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '25%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                  }}
                >
                  <CircularProgress color="inherit" thickness={4} size={100} />
                </Container>
              </Backdrop>
            )}
          </Box>
        </SnackbarProvider>
        <SnackbarProvider maxSnack={4}>
          <Box
            id="mapContainer2"
            className="mapboxgl-canvas2"
            sx={{ border: '1px black solid', top: `${headerTopValue}px` }}
          >
            <div id="dummy-cursor-right" className="crosshair"></div>
            <Box
              sx={{
                width: '100%',
                position: 'absolute',
                height: '80px',
                zIndex: 30,
                display: 'flex',
                justifyContent: 'space-between',
              }}
              onMouseOver={(e) => {
                hideRightDummyCursor();
              }}
            >
              <Box sx={{ margin: '0px 10px 0px 10px' }}>
                <FormControl
                  variant="outlined"
                  sx={{
                    backgroundColor: '#ffffff',
                    color: 'black !important',
                    height: '45px',
                    minWidth: 120,
                    marginRight: '20px',
                    marginTop: '11px !important',
                    borderRadius: '4px',
                    borderTop: '12px solid white',
                    boxShadow:
                      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <InputLabel
                    id="raster2"
                    shrink={rasterProduct2 !== ''}
                    sx={{ color: 'black !important', marginTop: '-8px' }}
                  >
                    Raster
                  </InputLabel>
                  <Select
                    labelId="raster2"
                    id="rasterRight"
                    label="Raster"
                    value={rasterProduct2}
                    sx={{ marginTop: '-10px' }}
                    onChange={(e: any) =>
                      handleRasterChange(
                        e,
                        setRasterProduct2,
                        mapRight,
                        'right'
                      )
                    }
                  >
                    {rasterProducts.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  sx={{
                    backgroundColor: '#ffffff',
                    color: 'black !important',
                    height: '45px',
                    width: 200,
                    marginTop: '11px !important',
                    borderRadius: '4px',
                    borderTop: '12px solid white',
                    boxShadow:
                      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <InputLabel
                    id="contourVector2"
                    shrink={cvProducts2.length !== 0}
                    sx={{ color: 'black !important', marginTop: '-8px' }}
                  >
                    Contour/Vector
                  </InputLabel>
                  <Select
                    label="Contour/Vector"
                    labelId="contourVector2"
                    id="cvRight"
                    multiple
                    open={openRight}
                    onOpen={() => setOpenRight(true)}
                    onClose={() => setOpenRight(false)}
                    sx={{ marginTop: '-10px' }}
                    value={cvProducts2}
                    onChange={(e: any) =>
                      handleChangeSelection(
                        e,
                        setCVProducts2,
                        mapRight,
                        'right'
                      )
                    }
                    renderValue={(selected) =>
                      selected.map((s) => s.split('_')[0]).join(', ')
                    }
                  >
                    {contourVectorProducts.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={cvProducts2.includes(name)} />
                        <ListItemText primary={name.split('_')[0]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ margin: '0px 10px 0px 10px' }}>
                <Card
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '56px',
                    width: '225px',
                    marginTop: '11px',
                    marginLeft: '20px',
                  }}
                >
                  <NavigateBeforeIcon
                    fontSize="large"
                    onClick={() =>
                      onChange(date2 || null, 'back', setDate2, 'right')
                    }
                    style={{ cursor: 'pointer', paddingLeft: '3px' }}
                  />
                  <DatePickerV2
                    defaultSelection={date2}
                    format="MM/dd/yyyy"
                    onChange={(date: Date | undefined) =>
                      onChange(date || date2!, null, setDate2, 'right')
                    }
                  />
                  <NavigateNextIcon
                    fontSize="large"
                    onClick={() =>
                      onChange(date2 || null, 'forward', setDate2, 'right')
                    }
                    style={{ cursor: 'pointer', paddingRight: '3px' }}
                  />
                </Card>
              </Box>
            </Box>
            {isAnyProductSelected(selectedProductsRight) ? (
              <SplitProductLegend
                side={'right'}
                selectedProducts={selectedProductsRight}
                cursorValue={cursorValue}
                dateValue={date2}
                tileData={tileDataRight}
                userRegion={userRegion}
                onClose={() => {
                  onCloseProducts(mapLayersRight, mapRight, 'right');
                }}
              />
            ) : null}
            {alertRight.display && (
              <SnackbarMUI
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertRight.display}
                sx={{ position: 'absolute' }}
                onClose={() => onClose('right')}
              >
                <Alert
                  onClose={() => onClose('right')}
                  severity={alertRight.type}
                  sx={{ width: '100%' }}
                >
                  {alertRight.message}
                </Alert>
              </SnackbarMUI>
            )}
            {loadingRight && (
              <Backdrop
                sx={{
                  color: '#ffffff',
                  zIndex: 999,
                  position: 'absolute',
                  left: '0%',
                }}
                open={loadingRight}
              >
                <Container
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: '99',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                  }}
                >
                  <CircularProgress color="inherit" thickness={4} size={100} />
                </Container>
              </Backdrop>
            )}
          </Box>
        </SnackbarProvider>
      </Stack>
      <MapFooter cursorValue={cursorValue?.lngLat} />
    </>
  );
};
