import { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Toolbar } from '@mui/material';
import { getUserAttributes } from '../../utils/auth';
import { dateToString } from '../../utils/util';
import IUUHeader from './IUUHeader';
import IUUFooter from './IUUFooter';
import VMSMain from './VMSMain';
import AISMain from './AISMain';
import { LicensedFeaturesContext } from '../Contexts/LicensedFeaturesContext';
import { useContextSelector } from 'use-context-selector';

const IUUDashboard: React.FC = () => {
  const { seastarId, userName } = getUserAttributes();
  const [dateRange, setDateRange] = useState([
    dateToString(moment().subtract(1, 'days'), 'YYYYMMDDHHmmss'),
    dateToString(moment(), 'YYYYMMDDHHmmss'),
  ]);
  const [mode, setMode] = useState('');
  const [modeList, setModeList] = useState<any[]>([]);
  const features = useContextSelector(
    LicensedFeaturesContext,
    (state: { features: any }) => state.features
  );
  const allModes = {
    ais: {
      key: 'ais',
      labelId: 'IUUDASHBOARD_AIS_TRACKING',
    },
    vms: {
      key: 'vms',
      labelId: 'IUUDASHBOARD_VMS_TRACKING',
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let modes = [];
    if (features.includes('AIS')) {
      modes.push(allModes['ais']);
    }
    if (features.includes('VMS')) {
      modes.push(allModes['vms']);
    }
    setModeList(modes);
    modes.length && setMode(modes[0].key);
  }, [features]);

  const onDateRangeChange = (newDateRange: any) => {
    const formatNewDateRange = [
      dateToString(newDateRange[0], 'YYYYMMDDHHmmss'),
      dateToString(newDateRange[1], 'YYYYMMDDHHmmss'),
    ];
    setDateRange(formatNewDateRange);
  };

  return (
    <Box>
      <IUUHeader
        userName={userName}
        seastarId={seastarId}
        onDateRangeChange={onDateRangeChange}
        modeList={modeList}
        mode={mode}
        setMode={setMode}
      />

      <div style={{ marginTop: '1em' }} />
      <Toolbar />
      {mode === 'ais' && <AISMain userName={userName} dateRange={dateRange} />}
      {mode === 'vms' && <VMSMain userName={userName} dateRange={dateRange} />}
      <IUUFooter />
    </Box>
  );
};

export default IUUDashboard;
