import '../Map.scss';
import { FeaturesContextProvider } from '../FeaturesContext';
import { getUserAttributes } from '../../../utils/auth';
import { LogoSection } from '../../../components/Header/LogoSection';
import { I18nKey } from '../../../translations/I18nKey';
import { Header } from '../../../components/Header/Header';
import { HeaderMenuSection } from '../../../components/Header/HeaderMenuSection';
import { HeaderMenuItemProps } from '../../../components/Header/HeaderTypes';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { SplitView } from './SplitView';

export const MapSplitScreen: React.FC<any> = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { userName, seastarId } = getUserAttributes();

  const menuList: Array<HeaderMenuItemProps> = [
    {
      key: '1',
      icon: LogoutIcon,
      name: intl.formatMessage({
        id: I18nKey.AUTH_LOGOUT,
      }),
      itemClass: 'menu__iconFormat',
      onClick: () => navigate('/logout'),
    },
  ];

  return (
    <FeaturesContextProvider>
      <>
        <Header
          leftElement={
            <LogoSection
              route={intl.formatMessage({
                id: I18nKey.HEADER_COMPARE,
              })}
            ></LogoSection>
          }
          rightElement={
            <HeaderMenuSection
              user={{ userName, seastarId }}
              menuList={menuList}
            ></HeaderMenuSection>
          }
        />
        <SplitView />
      </>
    </FeaturesContextProvider>
  );
};
