export const layerOrder = (layerName: string, map: any, mapLayers: any) => {
  if (mapLayers) {
    // mapLayers = mapLayers;
    switch (layerName) {
      case 'base': {
        return map?.getLayer(mapLayers.raster)
          ? mapLayers.raster
          : mapLayers.contour.length
          ? mapLayers.contour[0]
          : mapLayers.vector.length
          ? mapLayers.vector[0]
          : '';
      }
      case 'raster': {
        return mapLayers.contour.length
          ? mapLayers.contour[0]
          : mapLayers.vector.length
          ? mapLayers.vector[0]
          : '';
      }
      case 'contour': {
        return mapLayers.vector.length ? mapLayers.vector[0] : '';
      }
      case 'vector': {
        return '';
      }
      //   case 'measure-lines': {
      //     return map?.getLayer(mapLayers.tripPoints)
      //       ? mapLayers.tripPoints
      //       : map?.getLayer('graticule')
      //       ? 'graticule'
      //       : '';
      //   }
      //   case 'measure-points': {
      //     return map?.getLayer('graticule') ? 'graticule' : '';
      //   }
      default: {
        return '';
      }
    }
  }
};
