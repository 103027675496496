export const generalSettingsStyles: any = {
  settings: {
    color: 'white',
    marginLeft: '31px',
    marginBottom: '15px',
  },
  selectMenu: {
    backgroundColor: '#3D3D3D !important',
    color: 'white !important',
    width: '152px !important',
    marginTop: '-10px !important',
  },
  selected: {
    backgroundColor: '#00000029',
  },
  lineStyle: {
    height: '1px',
    backgroundColor: '#242424',
    border: 'none',
  },
  toggleFormat: {
    marginTop: '5px',
  },
  regionAutocomplete: {
    marginTop: '27px',
    marginLeft: '0px',
    height: '55px',
    width: '204px',
    backgroundColor: '#3d3d3d',
    borderRadius: '3px',
  },
};
