import React from 'react';
import { useState } from 'react';
import { IconButton, Menu, MenuItem, MenuList, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { HeaderMenuItemProps, MenuSectionProps } from './HeaderTypes';
import { menuStyles } from './MenuStyles';
import './HeaderMenuSection.scss';

const useStyles: any = makeStyles(menuStyles);

export const HeaderMenuSection: React.FC<MenuSectionProps> = ({
  user,
  userClass = 'menu__userName',
  menuList,
}) => {
  const classes = useStyles();
  const [menuItems, setMenuItems] = useState<null | HTMLElement>(null);

  const onToggleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement> | null
  ) => {
    Boolean(menuItems)
      ? setMenuItems(null)
      : setMenuItems(event && event.currentTarget);
  };

  const onClose = () => {
    setMenuItems(null);
  };

  const onClick =
    (onClickHandler: any) => (e: { (event: any): void; (): void }) => {
      onClose();
      onClickHandler();
    };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '120px',
          marginLeft: '37px',
          position: 'relative',
          top: '6px',
        }}
      >
        <p title={user.userName} className={userClass}>
          {user.userName}
        </p>
        <IconButton
          id="user-button"
          onClick={onToggleMenuClick}
          className="menu__iconStyle"
          aria-controls={Boolean(menuItems) ? 'user-menu' : undefined}
          aria-haspopup
          aria-expanded={Boolean(menuItems)}
        >
          <ExpandMoreIcon
            className={classnames({
              'menu__icon--unselected': !Boolean(menuItems),
              'menu__icon--selected': Boolean(menuItems),
            })}
          />
        </IconButton>
        <br />
        <Menu
          id="user-menu"
          anchorEl={menuItems}
          open={Boolean(menuItems)}
          onClose={onClose}
          MenuListProps={{
            'aria-labelledby': 'user-button',
          }}
          classes={{ paper: classes.menuFormat }}
          disableScrollLock={true}
        >
          <MenuList classes={{ root: classes.menuPadding }}>
            {menuList?.map(
              ({
                showInDropdown = true,
                key,
                name,
                icon,
                itemClass,
                onClick: onClickHandler,
                disabled,
              }) =>
                showInDropdown && (
                  <HeaderMenuItem
                    key={key}
                    name={name}
                    icon={icon}
                    itemClass={itemClass}
                    onClick={onClick(onClickHandler)}
                    disabled={disabled}
                  ></HeaderMenuItem>
                )
            )}
          </MenuList>
        </Menu>
      </Box>
    </>
  );
};

const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({
  name,
  icon,
  itemClass,
  onClick,
  disabled = false,
}) => {
  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      {React.createElement(icon, { className: itemClass })}
      <span>{name}</span>
    </MenuItem>
  );
};
