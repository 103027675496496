import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { isEmpty } from 'lodash';
import { Box } from '@mui/material';
import moment from 'moment';
import { useIntl } from 'react-intl';
import ProductClient from '../../../api/productAPIs';
import MasterCastClient from '../../../api/masterCastAPIs';
import { ProgressSpinner } from '../../../components/ProgressSpinner/ProgressSpinner';
import Snackbar from '../../../components/Snackbar/Snackbar';
import { I18nKey } from '../../../translations/I18nKey';
import { getUserAttributes } from '../../../utils/auth';
import { UserSettingsContext } from '../../Contexts/UserSettingsContext';
import { Geojson } from '../../types';
import { MapContext } from '../MapContext';
import { FillAreaService } from '../Products/MapLayers/FillAreaService';
import {
  getCourse,
  getDistance,
  removeLayerSource,
  stringToColorConverter,
} from '../HelperService';
import { reccMetaData } from './config';
import { RecommendationLegends } from './Recommendation/RecommendationLegend';
import { AnalysisRecommendationPopup } from './Recommendation/AnalysisRecommendationPopup';
import { TripsContainer } from './Trips/TripsContainer';
import { MapMainStyles, psdbStyle } from '../MapStyles';
import { MastercastSetting } from './Mastercast/MasterCastSetting';
import { LayoutContext } from '../LayoutContext';
import { useContextSelector } from 'use-context-selector';
import { OtherFeaturesContext } from '../OtherFeaturesContext';
import mapboxgl from 'mapbox-gl';
import { useNavigate } from 'react-router-dom';

export const AnalysisContainer: React.FC<any> = ({ openSettings }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const userName = getUserAttributes().userName;

  const layoutState = useContextSelector(
    LayoutContext,
    (state) => state.layoutState
  );
  const userSettings = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings
  );
  const removeMapLayerId = useContextSelector(
    MapContext,
    (state) => state.removeMapLayerId
  );
  const region = userSettings.map?.regionsOfInterest || '';
  const date =
    sessionStorage.getItem('date') ||
    `${moment(new Date()).format('YYYYMMDD')}`;
  const displayConfig = useContextSelector(
    OtherFeaturesContext,
    (state) => state.displayConfig
  );
  const setClickedProduct = useContextSelector(
    OtherFeaturesContext,
    (state) => state.setClickedProduct
  );
  const map = useContextSelector(MapContext, (state) => state.map);
  const selectedDate = useContextSelector(
    MapContext,
    (state) => state.selectedDate
  );

  const addMapLayerId = useContextSelector(
    MapContext,
    (state) => state.addMapLayerId
  );

  const layerOrder = useContextSelector(
    MapContext,
    (state) => state.layerOrder
  );
  const [mcSettingsApplied, setmcSettingsApplied] = useState(1);
  const [recommendationLayerList, setRecommendationLayerList] = useState(
    [] as any
  );

  const [recommendationSettings, setRecommendationSettings] = useState(
    [] as any
  );
  const [reccLastUpdated, setReccLastUpdated] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });
  const [showRecommendationsSettings, setShowRecommendationSettings] =
    useState<boolean>(false);
  const [mastercastSettingData, setMastercastSettings] = useState<any>();
  const rangeMarkersRef = useRef(new Map<string, mapboxgl.Marker>());
  const [rangeGeojson, setRangeGeojson] = useState<any>({
    type: 'FeatureCollection',
    features: [] as any,
  });
  const [compareWindow, setCompareWindow] = useState<any>();

  useEffect(() => {
    getMastercastDefaults();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      loadMasterCast();
      loadRecommendation();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (userSettings.map?.regionsOfInterest) {
      loadMasterCast();
      loadRecommendation();
    }
  }, [userSettings.map?.regionsOfInterest]);

  useEffect(() => {
    loadRecommendation();
  }, [displayConfig?.data?.analysis?.items.recommendations.selected]);

  useEffect(() => {
    loadMasterCast();
  }, [
    displayConfig?.data?.analysis?.items.mastercast.selected,
    mcSettingsApplied,
  ]);

  useEffect(() => {
    if (displayConfig?.data?.analysis?.items.rangeBearing.selected) {
      loadRangeBearing();
    } else {
      rangeMarkersRef.current.forEach((item) => item.remove());
      rangeMarkersRef.current.clear();
      setRangeGeojson({});
      if (map?.getSource('rangeGeojson')) {
        map?.removeLayer('distanceLines');
        map?.removeLayer('distancePoints');
        map?.removeSource('rangeGeojson');
      }
    }
  }, [displayConfig?.data?.analysis?.items.rangeBearing.selected]);

  useEffect(() => {
    if (displayConfig?.data?.analysis?.items.compareProducts.selected) {
      navigate('/compare');
    }
  }, [displayConfig?.data?.analysis?.items.compareProducts.selected]);

  const createPoint = (count: number) => {
    const r = 8;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    const html = `<div class="tooltip"><svg width="${w}" height="${w}"  text-anchor="middle"  style="font: 8px sans-serif; display: block"><circle cx="${r}" cy="${r}" r="${
      r0 + 2
    }" fill="blue" /><circle cx="${r}" cy="${r}" r="${r0}" fill="white" /><text dominant-baseline="central" transform="translate(${r},${r})">${count}</text></svg><pre class="tooltiptext"></pre></div>`;

    const el = document.createElement('div');
    el.innerHTML = html;
    return el;
  };

  const loadRangeBearing = () => {
    let center = map?.getCenter();
    let points: any = [];
    if (center) {
      points = [
        [center.lng - 6, center.lat + 4],
        [center.lng + 6, center.lat - 4],
      ];
    }

    let pointsJson = points.map((p: any, i: number) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: p,
        },
        properties: {
          id: String(i + 1),
        },
      };
    });
    pointsJson.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: points,
      },
      properties: { id: '3' },
    });
    let geojson: any = { type: 'FeatureCollection', features: pointsJson };
    setRangeGeojson({ ...geojson });
    map?.addSource('rangeGeojson', {
      type: 'geojson',
      data: geojson,
    });
    map?.addLayer({
      id: 'distanceLines',
      type: 'line',
      source: 'rangeGeojson',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'black',
        'line-width': 2.5,
        'line-dasharray': [0.2, 2],
      },
      filter: ['in', '$type', 'LineString'],
    });
    map?.addLayer({
      id: 'distancePoints',
      type: 'circle',
      source: 'rangeGeojson',
      paint: {
        'circle-radius': 5,
        'circle-color': '#fff',
        'circle-stroke-color': 'blue',
        'circle-stroke-width': 2,
      },
      filter: ['in', '$type', 'Point'],
    });
    const el1 = createPoint(1);
    const el2 = createPoint(2);
    const marker1 = new mapboxgl.Marker({
      element: el1,
      draggable: true,
    }).setLngLat(points[0]);
    const marker2 = new mapboxgl.Marker({
      element: el2,
      draggable: true,
    }).setLngLat(points[1]);
    setRangeMarkerDrag(marker1, geojson);
    setRangeMarkerDrag(marker2, geojson);

    if (map) {
      marker1.addTo(map);
      marker2.addTo(map);
      rangeMarkersRef.current.set('1', marker1);
      rangeMarkersRef.current.set('2', marker2);
    }
    let i = 0;
    rangeMarkersRef.current.forEach((value: mapboxgl.Marker) => {
      if (i === 0) {
        i++;
        value.getElement().childNodes[0].childNodes[1]?.remove();

        return;
      }
      const distance = getDistance(
        Number(rangeMarkersRef.current.get('1')?.getLngLat().lat),
        Number(rangeMarkersRef.current.get('1')?.getLngLat().lng),
        Number(rangeMarkersRef.current.get('2')?.getLngLat().lat),
        Number(rangeMarkersRef.current.get('2')?.getLngLat().lng),
        'N'
      );

      const course = getCourse(
        Number(rangeMarkersRef.current.get('1')?.getLngLat().lat),
        Number(rangeMarkersRef.current.get('1')?.getLngLat().lng),
        Number(rangeMarkersRef.current.get('2')?.getLngLat().lat),
        Number(rangeMarkersRef.current.get('2')?.getLngLat().lng)
      );
      value.getElement().childNodes[0].childNodes[1].textContent = `Distance: ${distance}nm\nCourse: ${course}°`;
      i++;
    });
  };

  const setRangeMarkerDrag = (marker: mapboxgl.Marker, rangeJson: any) => {
    marker.on('drag', (e: any) => {
      onRangeDrag(
        e.target._lngLat.lat,
        e.target._lngLat.lng,
        e.target.getElement().childNodes[0].childNodes[0].childNodes[2]
          .textContent,
        rangeJson
      );
    });
  };

  const onRangeDrag = (
    lat: number,
    lon: number,
    index: number,
    rangeJson: any
  ) => {
    rangeMarkersRef.current
      .get(rangeJson.features[index - 1].properties.id)
      ?.setLngLat([lon, lat]);
    let geojson = rangeJson;
    geojson.features[index - 1].geometry.coordinates[0] = lon;
    geojson.features[index - 1].geometry.coordinates[1] = lat;
    geojson.features[2].geometry.coordinates[index - 1] = [lon, lat];
    setRangeGeojson({ ...geojson });
    (map?.getSource('rangeGeojson') as mapboxgl.GeoJSONSource).setData(
      rangeJson
    );
    updateRangeDistance();
  };

  const updateRangeDistance = () => {
    let i = 0;
    rangeMarkersRef.current.forEach((value: mapboxgl.Marker) => {
      if (i === 0) {
        i++;
        value.getElement().childNodes[0].childNodes[1]?.remove();
        return;
      }
      const distance = getDistance(
        Number(rangeMarkersRef.current.get('1')?.getLngLat().lat),
        Number(rangeMarkersRef.current.get('1')?.getLngLat().lng),
        Number(rangeMarkersRef.current.get('2')?.getLngLat().lat),
        Number(rangeMarkersRef.current.get('2')?.getLngLat().lng),
        'N'
      );

      const course = getCourse(
        Number(rangeMarkersRef.current.get('1')?.getLngLat().lat),
        Number(rangeMarkersRef.current.get('1')?.getLngLat().lng),
        Number(rangeMarkersRef.current.get('2')?.getLngLat().lat),
        Number(rangeMarkersRef.current.get('2')?.getLngLat().lng)
      );
      value.getElement().childNodes[0].childNodes[1].textContent = `Distance: ${distance} nm\nCourse: ${course}°`;
      i++;
    });
  };

  const loadRecommendation = () => {
    removeRecommendations();
    if (displayConfig?.data?.analysis?.items.recommendations.selected) {
      setLoading(true);
      getRecommendationData();
    } else {
      setShowRecommendationSettings(false);
    }
  };

  const removeRecommendations = () => {
    if (!isEmpty(recommendationLayerList)) {
      recommendationLayerList.forEach((layer: any) => {
        if (map) {
          removeLayerSource(map, layer.name, [layer.name]);
          removeMapLayerId('recommendation', layer.name);
        }
      });
      setRecommendationLayerList([]);
    }
  };

  const getMastercastDefaults = async () => {
    try {
      const settings = await MasterCastClient.getMastercastDefaults();
      if (!_.isEmpty(settings)) setMastercastSettings(settings);
    } catch (error) {
      console.error(error);
      displayAlert('error', I18nKey.ERROR_MESSAGE_GET);
    }
  };

  const loadMasterCast = () => {
    if (map) {
      removeLayerSource(map, 'mastercast', ['mastercast']);
      removeMapLayerId('mastercast', 'mastercast');
    }
    if (displayConfig?.data?.analysis?.items.mastercast.selected) {
      setLoading(true);
      getMasterCastData();
    }
  };

  useEffect(() => {
    if (mcSettingsApplied > 1)
      displayAlert('success', I18nKey.USER_SETTINGS_SAVE_SUCCESS);
  }, [mcSettingsApplied]);

  const getRecommendationData = async () => {
    try {
      const data = await ProductClient.getRecommendations(
        userName,
        date,
        region,
        'all',
        'recommendation',
        -1,
        true
      );
      if (!isEmpty(data)) {
        setReccLastUpdated(data.begin_effective_time);
        _.map(data, (url, fish: string) => {
          if (!reccMetaData.includes(fish)) {
            const color = stringToColorConverter(fish.toLowerCase());
            setRecommendationLayerList((prevState: any[]) => [
              ...prevState,
              {
                name: fish,
                color: color,
                visible: true,
              },
            ]);
            getRecommendationGeojson(fish, url, color);
          }
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setClickedProduct('analysis', 'recommendations');
      console.log({ error });
      if (error.response.status === 400)
        displayAlert('error', I18nKey.ERROR_MESSAGE_UNAVAILABLE_FOR_REGION);
      else {
        displayAlert('error', I18nKey.ERROR_MESSAGE_GET);
      }
    }
  };

  const getRecommendationGeojson = async (
    fish: string,
    url: string,
    color: string
  ) => {
    const data = await axios.get(url);
    if (data.data) {
      map &&
        FillAreaService.addFillAreaLayer(
          map,
          fish,
          data.data,
          true,
          {
            'fill-color': color,
          },
          true,
          'recommendation',
          false,
          {},
          addMapLayerId,
          layerOrder
        );
    }
  };

  const displayAlert = (type: string, messageKey: any) => {
    setAlert({
      type: type,
      display: true,
      message: intl.formatMessage({
        id: messageKey,
      }),
    });
    setTimeout(() => {
      setAlert({
        type: '',
        display: false,
        message: '',
      });
    }, 5000);
  };

  const onCloseRecommendation = () => {
    setClickedProduct('analysis', 'recommendations');
    removeRecommendations();
    setShowRecommendationSettings(false);
  };

  const onRecommendationLegendSettings = () => {
    setShowRecommendationSettings((prevState) => !prevState);
  };

  const onRecommendationLegendSettingsApply = () => {
    onRecommendationLegendSettings();
    recommendationLayerList.forEach((layer: any) => {
      if (map) {
        map.setLayoutProperty(
          layer.name,
          'visibility',
          layer.visible === true ? 'visible' : 'none'
        );
      }
    });

    setRecommendationSettings(recommendationLayerList);
  };

  const onCloseTrips = () => {
    setClickedProduct('analysis', 'tripData');
  };

  const closeMastecastSetting = () => {
    setClickedProduct('analysis', 'mastercastSettings');
  };

  const getMasterCastData = async () => {
    try {
      const masterCastGeojson = await MasterCastClient.generateMasterCast(
        date,
        region
      );
      if (!isEmpty(masterCastGeojson.features)) {
        drawMasterCastLayer(
          masterCastGeojson,
          mastercastSettingData.displaySettings
        );
      } else {
        displayAlert('error', I18nKey.MASTERCAST_SETTINGS_NO_DATA_MSG);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      displayAlert('error', I18nKey.ERROR_MESSAGE_GET);
    }
  };

  const drawMasterCastLayer = (
    masterCastGeojson: Geojson,
    displaySettings: { width: any; color: any; opacity: any }
  ) => {
    map && removeLayerSource(map, 'mastercast', ['mastercast']);
    addMapLayerId('mastercast', 'mastercast');
    map?.addSource('mastercast', {
      type: 'geojson',
      data: masterCastGeojson,
    });
    map?.addLayer(
      {
        id: 'mastercast',
        source: 'mastercast',
        type: 'circle',
        paint: {
          'circle-radius': displaySettings.width,
          'circle-color': displaySettings.color,
          'circle-opacity': parseInt(displaySettings.opacity) / 100,
        },
      },
      layerOrder('mastercast')
    );
  };
  return (
    <>
      {loading && <ProgressSpinner showSpinner={loading} />}
      {alert.display && (
        <Snackbar
          type={alert.type}
          display={alert.display}
          message={alert.message}
        ></Snackbar>
      )}
      {displayConfig?.data?.analysis?.items.mastercastSettings.selected && (
        <Box
          sx={
            //TODO: change this code using LayoutContext/any other alternative
            openSettings
              ? psdbStyle(layoutState.Depth).openSetting
              : psdbStyle(layoutState.Depth).closeSetting
          }
        >
          <MastercastSetting
            mastercastSettingData={mastercastSettingData}
            setMastercastSettings={setMastercastSettings}
            setmcSettingsApplied={setmcSettingsApplied}
            closeMastecastSetting={closeMastecastSetting}
            displayAlert={displayAlert}
          />
        </Box>
      )}

      <Box
        sx={[
          { position: 'absolute' },
          layoutState.ProductLegend ? { bottom: '26%' } : { bottom: '5%' },
          layoutState.AISLegend &&
          layoutState.AISnVMSTracks &&
          layoutState.ProductLegend
            ? { top: '85px' }
            : {},
          layoutState.Depth &&
          layoutState.AISLegend &&
          layoutState.AISnVMSTracks
            ? { left: '14%' }
            : { left: '4%' },
        ]}
      >
        {displayConfig?.data?.analysis?.items.recommendations.selected && (
          <RecommendationLegends
            reccLastUpdated={reccLastUpdated}
            onRecommendationSettings={onRecommendationLegendSettings}
            recommendationData={recommendationLayerList}
            recommendationSettingsData={recommendationSettings}
            onCloseSettings={onCloseRecommendation}
          />
        )}
      </Box>
      {showRecommendationsSettings && (
        <Box
          sx={
            openSettings
              ? MapMainStyles.openSetting
              : MapMainStyles.closeSetting
          }
        >
          <AnalysisRecommendationPopup
            recommendationLayerList={recommendationLayerList}
            setRecommendationLayerList={setRecommendationLayerList}
            onClose={onRecommendationLegendSettings}
            onRecommendationLegendSettingsApply={
              onRecommendationLegendSettingsApply
            }
          />
        </Box>
      )}

      {displayConfig?.data?.analysis?.items.tripData.selected && (
        <Box
          sx={
            openSettings
              ? MapMainStyles.openSetting
              : MapMainStyles.closeSetting
          }
        >
          <TripsContainer onClose={onCloseTrips} />
        </Box>
      )}
    </>
  );
};
